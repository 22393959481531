import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@repo/ui/components/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@repo/ui/components/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@repo/ui/components/form'
import { Input } from '@repo/ui/components/input'
import { Label } from '@repo/ui/components/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@repo/ui/components/select'
import { toast } from '@repo/ui/hooks/use-toast'
import { useQueryClient } from '@tanstack/react-query'
import { Pencil } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { getTagsQueryKey, saveTag, Tag } from './tagService'

const hexColorRegex = /^#([0-9A-Fa-f]{3}){1,2}$/
const noSpecialCharsRegex = /^[a-zA-Z0-9\s]*$/

export const AddTagSchema = z.object({
  name: z.string().min(2, 'Please provide a valid tag name.'),
  description: z.string().regex(noSpecialCharsRegex, 'Special characters are not allowed').optional(),
  colour: z.string().regex(hexColorRegex, 'Invalid hex color code')
})

const colourSet: string[] = [
  '#F1E200', // yellow,
  '#F8A7C1', // pink,
  '#008080', // teal,
  '#B0B0B0', // grey,
  '#98FF98', // light green,
  '#E6E6FA', // lavender,
  '#FF7F50', // coral,
  '#DC143C' // crimson,
]

type TagFormProps = {
  updateTag?: boolean
  tagForm?: Tag
  tagId?: number
}

export function TagForm({ updateTag = false, tagForm, tagId }: TagFormProps) {
  const queryClient = useQueryClient()
  const [showDialog, setShowDialog] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const form = useForm({
    resolver: zodResolver(AddTagSchema),
    defaultValues: tagForm || { name: '', description: '', colour: colourSet[0] },
    mode: 'onChange'
  })

  useEffect(() => {
    if (tagForm) {
      form.reset(tagForm)
    }
  }, [form, tagForm])

  const onSubmit = async (data: Partial<Tag>) => {
    try {
      setSubmitting(true)
      await saveTag(data, updateTag ? tagId : undefined)
      setShowDialog(false)
      form.reset()
      setSubmitting(false)
      toast({ title: `Tag ${updateTag ? 'updated' : 'created'}.` })

      queryClient.invalidateQueries({ queryKey: [getTagsQueryKey] })
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An error occurred.')
      setSubmitting(false)
    }
  }
  const onClose = () => {
    setShowDialog(false)
    form.reset()
    setError(null)
    setSubmitting(false)
  }

  return (
    <Dialog open={showDialog}>
      <DialogTrigger asChild>
        {updateTag ? (
          <Pencil size={18} onClick={() => setShowDialog(true)} style={{ cursor: 'pointer' }} />
        ) : (
          <Button onClick={() => setShowDialog(true)}>New Tag</Button>
        )}
      </DialogTrigger>

      <DialogContent className="bg-white sm:max-w-md [&>button]:hidden">
        <DialogHeader>
          <DialogTitle>{updateTag ? 'Update Tag' : 'New Tag'}</DialogTitle>
          <DialogDescription className="sr-only">
            {updateTag ? 'Create Tag Modal' : 'Update Tag Modal'}
          </DialogDescription>
          {error && <div className="text-destructive">{error}</div>}
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-bold text-xs mb-1">Tag Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Name" {...field} disabled={submitting} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-bold text-xs mb-1">Tag Description</FormLabel>
                  <FormControl>
                    <Input placeholder="Description" {...field} disabled={submitting} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="colour"
              render={({ field }) => (
                <FormItem>
                  <Label className="font-bold text-xs mb-1">Colour</Label>

                  <Select name="colour" value={field.value} onValueChange={field.onChange} disabled={submitting}>
                    <FormControl>
                      <SelectTrigger className="w-auto">
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Color</SelectLabel>
                        {colourSet.map((colour) => (
                          <SelectItem key={colour} value={colour}>
                            <div className="flex items-center">
                              <div className="w-5 h-5 mr-2" style={{ backgroundColor: colour }} />
                              {colour}
                            </div>
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>

                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter className="sm:justify-between">
              <DialogClose asChild>
                <Button type="button" variant="secondary" onClick={onClose}>
                  Close
                </Button>
              </DialogClose>
              <Button type="submit" disabled={submitting || !form.formState.isDirty}>
                {updateTag ? 'Update' : 'Create'} Tag
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
