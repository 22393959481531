import { AlertDialog } from '@repo/ui/components/alert-dialog'
import { Button } from '@repo/ui/components/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@repo/ui/components/dialog'
import { useQueryClient } from '@tanstack/react-query'
import { ComponentProps, useState } from 'react'
import { deleteTag, getTagsQueryKey } from './tagService'

export const DeleteTagDialog: React.FC<ComponentProps<typeof AlertDialog> & { tagId: number }> = ({
  tagId,
  ...props
}) => {
  const queryClient = useQueryClient()
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async () => {
    try {
      setSubmitting(true)
      await deleteTag(tagId)
      props.onOpenChange?.(false)
    } catch (e) {
      if (e instanceof Error) {
        console.error(e)
        setError(e.message)
      }
    } finally {
      setSubmitting(false)
      queryClient.invalidateQueries({ queryKey: [getTagsQueryKey], exact: false })
    }
  }

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Tag</DialogTitle>
          <DialogDescription className="sr-only">Delete Tag Modal</DialogDescription>
        </DialogHeader>
        <p>Are you sure you want to delete this tag? This action cannot be undone.</p>
        {error && <p className="mt-[0.35rem] text-destructive">{error}</p>}
        <DialogFooter>
          <Button variant="outline" onClick={() => props.onOpenChange?.(false)}>
            Cancel
          </Button>
          <Button variant="destructive" disabled={submitting} onClick={onSubmit}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
