import { Button } from '@repo/ui/components/button'
import { EmailIcon, ReplyToIcon } from '@repo/ui/components/icons'
import { Skeleton } from '@repo/ui/components/skeleton'
import { Typography } from '@repo/ui/components/typography'
import { cn } from '../../../../../lib/utils'
import { EmailData } from '../../emailAgentConcept'

interface Props {
  emailData: EmailData[]
  isLoading: boolean
  setActive: React.Dispatch<React.SetStateAction<number>>
  active: EmailData['id']
}

export function EmailList({ emailData, isLoading, setActive, active }: Props) {
  return (
    <div className="w-64 min-w-64">
      <div className="flex w-full flex-col divide-y-[1px] divide-solid overflow-hidden rounded-sm border border-border">
        {isLoading ? (
          <>
            <EmailItemSkeleton />
            <EmailItemSkeleton />
            <EmailItemSkeleton />
          </>
        ) : (
          <>
            {emailData.map((email, index) => (
              <EmailItem key={index} email={email} setActive={setActive} active={active === email.id} />
            ))}
          </>
        )}
      </div>
    </div>
  )
}

interface EmailItemProps {
  active?: boolean
  email: EmailData
  setActive: React.Dispatch<React.SetStateAction<number>>
}
function EmailItem({ email, setActive, active }: EmailItemProps) {
  const { from, id, state } = email
  return (
    <Button
      className={cn(
        'flex h-[36x] w-full cursor-pointer items-center justify-start gap-2 rounded-none border-none bg-white px-5 py-3 text-left',
        active && 'bg-[#efebf8]'
      )}
      onClick={() => setActive(id)}
    >
      {state === 'DRAFT' && <div className="w-[24px]" />}
      {state === 'REPLIED' && <EmailIcon className="!h-6 !w-6 fill-[#666666]" />}
      {state === 'UNREAD' && <ReplyToIcon className="!h-6 !w-6 fill-[#666666]" />}
      <div className="flex flex-col pl-2">
        <Typography margin="off" className="text-sm font-bold text-black">
          {from.name}
        </Typography>
        <Typography margin="off" className="text-xs text-muted-foreground">
          {from.dateReceived}
        </Typography>
      </div>
    </Button>
  )
}

function EmailItemSkeleton() {
  return (
    <div className="flex h-[60px] w-full items-center justify-start gap-2 rounded-none border-none bg-white px-5 py-3 text-left">
      <Skeleton className="h-[24px] min-w-[24px] rounded-full" />
      <div className="flex w-full flex-col space-y-1 pl-2">
        <Skeleton className="block h-3 w-full" />
        <Skeleton className="block h-2 w-1/3" />
      </div>
    </div>
  )
}
