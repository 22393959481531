import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@repo/ui/components/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@repo/ui/components/form'
import { MultiSelect } from '@repo/ui/components/multiselect'
import { Textarea } from '@repo/ui/components/textarea'
import { toast } from '@repo/ui/hooks/use-toast'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { getTags, getTagsQueryKey, Tag } from '../../settings/_components/tags/tagService'
import { AddRagText, AddRagTextFormSchema, saveRagText } from './ragTagService'
import { getRagTextsQueryKey } from './storedText'

export interface TagOption {
  readonly value: string
  readonly label: string
  readonly labelColour: string
}
export interface UploadRagTextProps {
  ragText?: AddRagText
  updateRagText?: boolean
  closeDialog?: () => void
}
export function UploadRagText(props: UploadRagTextProps) {
  const queryClient = useQueryClient()
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const form = useForm<AddRagText>({
    resolver: zodResolver(AddRagTextFormSchema),
    defaultValues: { text: props.ragText?.text || '', tagIds: props.ragText?.tagIds || [] },
    mode: 'onSubmit'
  })

  const { data: tags = [] } = useQuery<Tag[]>({
    queryKey: [getTagsQueryKey],
    queryFn: getTags
  })

  const tagOptions: TagOption[] = tags.map((tag) => ({
    value: tag.tagId.toString(),
    label: tag.name,
    labelColour: tag.colour
  }))

  const onClose = () => {
    form.reset()
    setError('')
    setSubmitting(false)
    if (props.closeDialog) {
      props.closeDialog()
    }
  }

  const onSubmit = async (data: AddRagText) => {
    try {
      setError('')
      setSubmitting(true)

      await saveRagText(
        {
          // Text is encoded to preserve formatting and special characters.
          text: encodeURIComponent(data.text),
          tagIds: data.tagIds || undefined
        },
        props.ragText?.docID || undefined
      )
      if (props.closeDialog) {
        props.closeDialog()
      }
      toast({ title: `The content has been uploaded` })
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message)
      }
    } finally {
      form.reset()
      setSubmitting(false)

      queryClient.invalidateQueries({ queryKey: [getRagTextsQueryKey], exact: false })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col space-y-8">
        <FormField
          control={form.control}
          name="text"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Upload text to your organisation's secure content vault.</FormLabel>
              <FormControl>
                <Textarea {...field} rows={10} placeholder="Enter the text to add" disabled={submitting} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="tagIds"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <MultiSelect
                  {...field}
                  options={tagOptions || []}
                  onValueChange={field.onChange}
                  value={field.value || []}
                />
              </FormControl>
            </FormItem>
          )}
        />

        <div className="flex flex-row justify-end space-x-4">
          {props.updateRagText && (
            <Button size="default" type="button" variant="secondary" onClick={onClose} disabled={submitting}>
              Cancel
            </Button>
          )}
          <Button size="default" align="start" type="submit" disabled={submitting}>
            {props.updateRagText ? 'Save' : 'Upload'}
          </Button>
        </div>
        {error && <p className="mt-[0.35rem] text-destructive">{error}</p>}
      </form>
    </Form>
  )
}
