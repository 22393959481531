import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "../lib/utils";

const typographyVariants = cva("text-primary", {
  variants: {
    variant: {
      primary: "text-primary",
      secondary: "text-secondary",
      brand: "text-brand",
    },
    size: {
      h1: "text-[24px] font-bold",
      h2: "text-[20px] font-bold",
      h3: "text-[16px] font-bold",
      h4: "text-[20px] font-bold",
      h5: "text-[16px] font-bold",
      h6: "text-[14px] font-bold",
      p: "text-[16px]",
    },
    margin: {
      on: "mb-4",
      off: "",
    }
  },
  defaultVariants: {
    variant: "primary",
    size: "p",
    margin: "on",
  },
});

export interface TypographyProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
  VariantProps<typeof typographyVariants> {
  asChild?: boolean;
}

const Typography = React.forwardRef<HTMLHeadingElement, TypographyProps>(
  ({ className, variant, size, margin, asChild = false, ...props }, ref) => {
    const Comp = size ?? "p";
    return (
      <Comp
        className={cn(typographyVariants({ variant, size, margin, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Typography.displayName = "Typography";

export { Typography, typographyVariants };
