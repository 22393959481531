import { Accordion, AccordionItem } from '@repo/ui/components/accordion'
import { AccordionTriggerTextBlocksLinked } from '@repo/ui/components/accordion-text-block'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@repo/ui/components/alert-dialog'
import { Button } from '@repo/ui/components/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@repo/ui/components/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@repo/ui/components/dropdown-menu'
import { QuestionAnswerPairIcon } from '@repo/ui/components/icons'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@repo/ui/components/table'
import { Textarea } from '@repo/ui/components/textarea'
import { Typography } from '@repo/ui/components/typography'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Ellipsis, Pencil, Trash } from 'lucide-react'
import { ComponentProps, useState } from 'react'
import { z } from 'zod'
import { apiErrorHandler } from '../../../api/errorHandler'
import { parseDataOfType } from '../../../common/parseDataType'

export const getQAPairsQueryKey = 'getQAPairs'

const ragQAPairSchema = z
  .object({
    id: z.number(),
    questionID: z.number(),
    question: z.string(),
    answerID: z.number(),
    answer: z.string(),
    qAndAPairUpdatedAtTsMs: z.number(),
    qAndAPairUpdatedByName: z.string()
  })
  .describe('RagQAPair')
type RagQAPair = z.infer<typeof ragQAPairSchema>

const ragQAPairPageSchema = z
  .object({
    items: z.array(ragQAPairSchema),
    pageCount: z.number(),
    currentPage: z.number(),
    nextPageTsMs: z.number(),
    previousPageTsMs: z.number()
  })
  .describe('RagQAPairPageSchema')
type RagQAPairPage = z.infer<typeof ragQAPairPageSchema>

async function getQAPairs({ previousPageTsMs, nextPageTsMs }: { previousPageTsMs?: number; nextPageTsMs?: number }) {
  const params = new URLSearchParams()
  if (nextPageTsMs) {
    params.append('nextPageTsMs', nextPageTsMs.toString())
  } else if (previousPageTsMs) {
    params.append('previousPageTsMs', previousPageTsMs.toString())
  }
  console.log('params', params)
  const res = await fetch(`${window.location.origin}/api/rag/qna?${params.toString()}`, {
    method: 'GET',
    credentials: 'same-origin'
  })
  await apiErrorHandler(res)

  const data = await res.json()
  const jsonData = parseDataOfType(data, ragQAPairPageSchema)
  // QA pairs are encoded to preserve formatting and special characters.
  for (const qAPair of jsonData.items) {
    qAPair.question = decodeURIComponent(qAPair.question)
    qAPair.answer = decodeURIComponent(qAPair.answer)
  }
  return jsonData
}

async function updateQAPair(id: string, updateData: { question: string; answer: string }) {
  const res = await fetch(`${window.location.origin}/api/rag/qna/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify({
      question: encodeURIComponent(updateData.question),
      answer: encodeURIComponent(updateData.answer)
    })
  })
  await apiErrorHandler(res)
}

async function deleteQAPair(id: string) {
  const res = await fetch(`${window.location.origin}/api/rag/qna/${id}`, {
    method: 'DELETE',
    credentials: 'same-origin'
  })
  await apiErrorHandler(res)
}

export function StoredQAPairs() {
  const [previousPageTsMs, setPreviousPageTsMs] = useState<number | undefined>()
  const [nextPageTsMs, setNextPageTsMs] = useState<number | undefined>()

  const getQAPairsQueryResult = useQuery<unknown, Error, RagQAPairPage>({
    queryKey: [getQAPairsQueryKey, { nextPageTsMs, previousPageTsMs }],
    queryFn: () => getQAPairs({ nextPageTsMs, previousPageTsMs })
  })

  const getPreviousPage = () => {
    setNextPageTsMs(undefined)
    setPreviousPageTsMs(getQAPairsQueryResult.data?.previousPageTsMs)
  }
  const getNextPage = () => {
    setPreviousPageTsMs(undefined)
    setNextPageTsMs(getQAPairsQueryResult.data?.nextPageTsMs)
  }

  return (
    <div className="w-full animate-appear opacity-0">
      <div className="flex flex-row justify-between items-center">
        <Typography variant="primary" size="h2" className="flex flex-row items-center space-x-2">
          <div>
            <QuestionAnswerPairIcon className="fill-brand" />
          </div>
          <div>Question & Answer Pairs for Email Agent</div>
        </Typography>
        <div className="flex flex-row items-center space-x-2">
          <Button
            onClick={getPreviousPage}
            disabled={getQAPairsQueryResult.isLoading || getQAPairsQueryResult.data?.currentPage === 1}
          >
            &lt;
          </Button>
          <Typography className="pt-4">
            Page {getQAPairsQueryResult.data?.currentPage} of {getQAPairsQueryResult.data?.pageCount}
          </Typography>
          <Button
            onClick={getNextPage}
            disabled={
              getQAPairsQueryResult.isLoading ||
              getQAPairsQueryResult.data?.currentPage === getQAPairsQueryResult.data?.pageCount
            }
          >
            &gt;
          </Button>
        </div>
      </div>

      <Table className="w-full">
        <TableHeader>
          <TableRow>
            <TableHead className="w-[40%]">Question</TableHead>
            <TableHead className="w-[40%]">Answer</TableHead>
            <TableHead className="min-w-[12em]">Updated By</TableHead>
            <TableHead className="min-w-[10em]">Last Updated</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {getQAPairsQueryResult.isLoading ? (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          ) : (
            (getQAPairsQueryResult.data?.items ?? []).map((qAPair) => <QATableRow qAPair={qAPair} />)
          )}
        </TableBody>
      </Table>
    </div>
  )
}

function QATableRow({ qAPair }: { qAPair: RagQAPair }) {
  const [open, setOpen] = useState(false)

  function onClick() {
    setOpen((prev) => !prev)
  }

  return (
    <TableRow key={qAPair.id}>
      <TableCell className="max-w-0 align-top">
        <Accordion type="single" collapsible>
          <AccordionItem value={'item-' + qAPair.questionID}>
            <AccordionTriggerTextBlocksLinked open={open} onClick={onClick}>
              {qAPair.question}{' '}
            </AccordionTriggerTextBlocksLinked>
          </AccordionItem>
        </Accordion>
      </TableCell>
      <TableCell className="max-w-0 align-top">
        <Accordion type="single" collapsible>
          <AccordionItem value={'item-' + qAPair.answerID}>
            <AccordionTriggerTextBlocksLinked open={open} onClick={onClick}>
              {qAPair.answer}
            </AccordionTriggerTextBlocksLinked>
          </AccordionItem>
        </Accordion>
      </TableCell>
      <TableCell>{qAPair.qAndAPairUpdatedByName}</TableCell>
      <TableCell className="whitespace-pre">
        {new Date(qAPair.qAndAPairUpdatedAtTsMs).toLocaleString().replace(', ', '\n')}
      </TableCell>
      <TableCell className="align-top">
        <Options qaPair={qAPair} />
      </TableCell>
    </TableRow>
  )
}

const Options: React.FC<{ qaPair: RagQAPair }> = ({ qaPair }) => {
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger>
          <Ellipsis />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => setShowEdit(true)}>
            <Pencil />
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowDelete(true)}>
            <Trash />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <EditDialog open={showEdit} onOpenChange={setShowEdit} qaPair={qaPair} />

      <DeleteDialog open={showDelete} onOpenChange={setShowDelete} qaPair={qaPair} />
    </>
  )
}

const EditDialog: React.FC<ComponentProps<typeof AlertDialog> & { qaPair: RagQAPair }> = ({ qaPair, ...props }) => {
  const queryClient = useQueryClient()
  const [question, setQuestion] = useState(qaPair.question)
  const [answer, setAnswer] = useState(qaPair.answer)
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async () => {
    if (question.length === 0 || answer.length === 0) {
      setError('Question and answer cannot be empty')
      return
    }

    try {
      setSubmitting(true)
      await updateQAPair(qaPair.id.toString(), { question, answer })
      props.onOpenChange?.(false)
      setError('')
    } catch (e) {
      if (e instanceof Error) {
        console.error(e)
        setError(e.message)
      }
    } finally {
      setSubmitting(false)
      queryClient.invalidateQueries({ queryKey: [getQAPairsQueryKey], exact: false })
    }
  }

  return (
    <AlertDialog {...props}>
      <AlertDialogContent className="max-w-4xl">
        <AlertDialogHeader>
          <AlertDialogTitle>Edit Q&A Pair</AlertDialogTitle>
          <AlertDialogDescription className="sr-only">Edit Q&A Pair Modal</AlertDialogDescription>
        </AlertDialogHeader>
        <div className="flex gap-8 justify-between">
          <div className="flex-1">
            <label className="text-sm font-medium">Question</label>
            <Textarea
              className="min-h-[200px]"
              value={question}
              onChange={(e) => setQuestion(e.currentTarget.value)}
              placeholder="Enter question..."
            />
          </div>
          <div className="flex-1">
            <label className="text-sm font-medium">Answer</label>
            <Textarea
              className="min-h-[200px]"
              value={answer}
              onChange={(e) => setAnswer(e.currentTarget.value)}
              placeholder="Enter answer..."
            />
          </div>
        </div>
        {error && <p className="mt-[0.35rem] text-destructive">{error}</p>}
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button disabled={submitting} onClick={onSubmit}>
            Save
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const DeleteDialog: React.FC<ComponentProps<typeof AlertDialog> & { qaPair: RagQAPair }> = ({ qaPair, ...props }) => {
  const queryClient = useQueryClient()
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async () => {
    try {
      setSubmitting(true)
      await deleteQAPair(qaPair.id.toString())
      setError('')
      props.onOpenChange?.(false)
    } catch (e) {
      if (e instanceof Error) {
        console.error(e)
        setError(e.message)
      }
    } finally {
      setSubmitting(false)
      queryClient.invalidateQueries({ queryKey: [getQAPairsQueryKey], exact: false })
    }
  }

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Text</DialogTitle>
          <DialogDescription className="sr-only">Delete Text Modal</DialogDescription>
        </DialogHeader>
        <p>Are you sure you want to delete this Q&A pair? This action cannot be undone.</p>
        {error && <p className="mt-[0.35rem] text-destructive">{error}</p>}
        <DialogFooter>
          <Button variant="outline" onClick={() => props.onOpenChange?.(false)}>
            Cancel
          </Button>
          <Button variant="destructive" disabled={submitting} onClick={onSubmit}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
