import { getReasonPhrase } from 'http-status-codes'
import { z } from 'zod'
import { parseDataOfType } from '../common/parseDataType'

const apiErrorSchema = z.object({
  error: z.object({
    kind: z.string(),
    message: z.string()
  })
})
type ApiError = z.infer<typeof apiErrorSchema>

export async function apiErrorHandler(res: Response) {
  if (!res.ok) {
    let apiError: ApiError
    try {
      apiError = parseDataOfType(await res.json(), apiErrorSchema)
    } catch {
      throw new Error(getReasonPhrase(res.status))
    }
    throw new Error(apiError.error.message)
  }
}
