import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from '@repo/ui/components/button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@repo/ui/components/form'
import { Input } from '@repo/ui/components/input'
import { toast } from '@repo/ui/hooks/use-toast'

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@repo/ui/components/dialog'
import { useState } from 'react'
import { apiErrorHandler } from '../../../../api/errorHandler'
import { useAuth } from '../../../../authentication'

const inviteUserFormSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
  first_name: z.string().min(2, {
    message: 'Please provide a valid first name.'
  }),
  surname: z
    .string()
    .min(2, {
      message: 'Please provide a valid surname.'
    })
    .optional()
})
type InviteUserForm = z.infer<typeof inviteUserFormSchema>

export function UsersForm() {
  const [showDialog, setShowDialog] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const auth = useAuth()

  const form = useForm<InviteUserForm>({
    resolver: zodResolver(inviteUserFormSchema),
    defaultValues: {
      email: '',
      first_name: '',
      surname: ''
    },
    mode: 'onChange'
  })

  const onSubmit = async (data: InviteUserForm) => {
    try {
      setSubmitting(true)
      const res = await fetch('/api/user/invite', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      await apiErrorHandler(res)

      setShowDialog(false)
      form.reset()
      setError(null)
      setSubmitting(false)
      toast({
        title: 'User Invite Sent'
      })
    } catch (error) {
      if (error instanceof Error) {
        console.error(error)
        setError(error.message)
        setSubmitting(false)
      }
    }
  }

  const onClose = () => {
    setShowDialog(false)
    form.reset()
    setError(null)
    setSubmitting(false)
  }

  return (
    <>
      <Dialog open={showDialog}>
        <DialogTrigger asChild>
          <Button onClick={() => setShowDialog(true)} disabled={auth.user?.org.admin === false}>
            Invite User
          </Button>
        </DialogTrigger>
        <DialogContent className="bg-white sm:max-w-md [&>button]:hidden">
          <DialogHeader>
            <DialogTitle>Invite User</DialogTitle>
            {error && <div className="text-destructive">{error}</div>}
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder="First Name" {...field} disabled={submitting} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="surname"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder="Surname" {...field} disabled={submitting} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input placeholder="User's email" {...field} disabled={submitting} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter className="sm:justify-between">
                <DialogClose asChild>
                  <Button type="button" variant="secondary" onClick={onClose}>
                    Close
                  </Button>
                </DialogClose>
                <Button type="submit" disabled={submitting}>
                  Send Invite
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  )
}
