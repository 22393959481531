'use client'

import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { ChevronDownIcon } from 'lucide-react'
import * as React from 'react'

import { cn } from '@repo/ui/lib/utils'

function AccordionTriggerTextBlock({
  className,
  children,
  ...props
}: React.ComponentProps<typeof AccordionPrimitive.Trigger>) {
  const [open, setOpen] = React.useState(false)
  function onClick() {
    setOpen((prev) => !prev)
  }
  return (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        value={open ? 'open' : 'closed'}
        onClick={onClick}
        data-slot="accordion-trigger"
        className={cn(
          'focus-visible:border-ring focus-visible:ring-ring/50 flex max-w-full flex-1 items-start justify-between gap-4 rounded-md py-4 text-left text-sm font-medium outline-none transition-all focus-visible:ring-[3px] disabled:pointer-events-none disabled:opacity-50 [&[data-state=open]>svg]:rotate-180',
          className
        )}
        {...props}
      >
        <div className={open ? 'whitespace-pre-wrap' : 'overflow-hidden text-ellipsis whitespace-nowrap'}>
          {children}
        </div>
        <ChevronDownIcon className="text-muted-foreground pointer-events-none size-4 shrink-0 translate-y-0.5 transition-transform duration-200" />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
}

function AccordionTriggerTextBlocksLinked({
  open,
  onClick,
  className,
  children,
  ...props
}: { open: boolean; onClick: () => void } & React.ComponentProps<typeof AccordionPrimitive.Trigger>) {
  return (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        value={open ? 'open' : 'closed'}
        onClick={onClick}
        data-slot="accordion-trigger"
        className={cn(
          'focus-visible:border-ring focus-visible:ring-ring/50 flex max-w-full flex-1 items-start justify-between gap-4 rounded-md py-4 text-left text-sm font-medium outline-none transition-all focus-visible:ring-[3px] disabled:pointer-events-none disabled:opacity-50 [&[data-state=open]>svg]:rotate-180',
          className
        )}
        {...props}
      >
        <div className={open ? 'whitespace-pre-wrap' : 'overflow-hidden text-ellipsis whitespace-nowrap'}>
          {children}
        </div>
        <ChevronDownIcon
          className={cn(
            'text-muted-foreground pointer-events-none size-4 shrink-0 translate-y-0.5 transition-transform duration-200',
            open ? 'rotate-180' : ''
          )}
        />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
}

export { AccordionTriggerTextBlock, AccordionTriggerTextBlocksLinked }
