import { Accordion, AccordionItem } from '@repo/ui/components/accordion'
import { AccordionTriggerTextBlock } from '@repo/ui/components/accordion-text-block'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle
} from '@repo/ui/components/alert-dialog'
import { Button } from '@repo/ui/components/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@repo/ui/components/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@repo/ui/components/dropdown-menu'
import { UploadPdfIcon } from '@repo/ui/components/icons'
import { Skeleton } from '@repo/ui/components/skeleton'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@repo/ui/components/table'
import { Typography } from '@repo/ui/components/typography'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Ellipsis, Pencil, Trash } from 'lucide-react'
import { ComponentProps, useState } from 'react'
import { AddRagText, deleteRagText, GetRagText, getRagTexts, RagTextPage } from './ragTagService'
import { UploadRagText } from './uploadText'

export const getRagTextsQueryKey = 'getRagTexts'

export function StoredRagTexts() {
  const [previousPageTsMs, setPreviousPageTsMs] = useState<number | undefined>()
  const [nextPageTsMs, setNextPageTsMs] = useState<number | undefined>()

  const getRagTextQueryResult = useQuery<unknown, Error, RagTextPage>({
    queryKey: [getRagTextsQueryKey, { nextPageTsMs, previousPageTsMs }],
    queryFn: () => getRagTexts({ nextPageTsMs, previousPageTsMs })
  })

  const getPreviousPage = () => {
    setNextPageTsMs(undefined)
    setPreviousPageTsMs(getRagTextQueryResult.data?.previousPageTsMs)
  }
  const getNextPage = () => {
    setPreviousPageTsMs(undefined)
    setNextPageTsMs(getRagTextQueryResult.data?.nextPageTsMs)
  }

  return (
    <div className="w-full animate-appear space-y-8 opacity-0">
      <div className="flex flex-row justify-between items-center">
        <Typography variant="primary" size="h2" className="flex flex-row items-center space-x-2">
          <div>
            <UploadPdfIcon className="fill-primary" />
          </div>
          <div>Text</div>
        </Typography>
        <div className="flex flex-row items-center space-x-2">
          <Button
            onClick={getPreviousPage}
            disabled={getRagTextQueryResult.isLoading || getRagTextQueryResult.data?.currentPage === 1}
          >
            &lt;
          </Button>
          <Typography className="pt-4">
            Page {getRagTextQueryResult.data?.currentPage} of {getRagTextQueryResult.data?.pageCount}
          </Typography>
          <Button
            onClick={getNextPage}
            disabled={
              getRagTextQueryResult.isLoading ||
              getRagTextQueryResult.data?.currentPage === getRagTextQueryResult.data?.pageCount
            }
          >
            &gt;
          </Button>
        </div>
      </div>
      <Table className="w-full">
        <TableHeader>
          <TableRow>
            <TableHead className="w-full">Text</TableHead>
            <TableHead className="min-w-[12em]">Tags</TableHead>
            <TableHead className="min-w-[12em]">Updated By</TableHead>
            <TableHead className="min-w-[10em]">Last Updated</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {getRagTextQueryResult.isLoading ? (
            <>
              {[...Array(4)].map((_, idx) => (
                <TableRow key={idx}>
                  <TableCell style={{ width: '100%' }}>
                    <Skeleton className="h-[24px] rounded-full m-2 w-full" />
                  </TableCell>
                  <TableCell style={{ width: '100%' }}>
                    <Skeleton className="h-[24px] rounded-full m-2 w-full" />
                  </TableCell>
                  <TableCell style={{ width: '100%' }}>
                    <Skeleton className="h-[24px] rounded-full m-2 w-full" />
                  </TableCell>
                  <TableCell style={{ width: '100%' }}>
                    <Skeleton className="h-[24px] rounded-full m-2 w-full" />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            (getRagTextQueryResult.data?.items ?? []).map((item) => (
              <TableRow key={item.docID}>
                <TableCell className="max-w-0">
                  <Accordion type="single" collapsible>
                    <AccordionItem value={'item-' + item.docID}>
                      <AccordionTriggerTextBlock>{item.text} </AccordionTriggerTextBlock>
                    </AccordionItem>
                  </Accordion>
                </TableCell>
                <TableCell>
                  {(item.tags ?? []).map((tag) => (
                    <div key={tag.tagId}>
                      <span className="h-auto w-auto rounded-md pl-2 pr-2" style={{ backgroundColor: tag.colour }}>
                        {tag.name}
                      </span>
                    </div>
                  ))}
                </TableCell>
                <TableCell>{item.updatedByName}</TableCell>
                <TableCell className="whitespace-pre">
                  {new Date(item.updatedAtTsMs).toLocaleString().replace(', ', '\n')}
                </TableCell>
                <TableCell className="align-top">
                  <Options item={item} />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </div>
  )
}

const Options: React.FC<{ item: GetRagText }> = ({ item, ...props }) => {
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const closeEditDialog = () => setShowEdit(false)
  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger>
          <Ellipsis />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => setShowEdit(true)}>
            <Pencil />
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowDelete(true)}>
            <Trash />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <EditDialog open={showEdit} onOpenChange={setShowEdit} ragText={item} closeDialog={closeEditDialog} {...props} />

      <DeleteDialog open={showDelete} onOpenChange={setShowDelete} ragText={item} />
    </>
  )
}

const EditDialog: React.FC<ComponentProps<typeof AlertDialog> & { ragText: GetRagText; closeDialog: () => void }> = ({
  ragText,
  closeDialog,
  ...props
}) => {
  return (
    <AlertDialog {...props}>
      <AlertDialogContent className="max-w-4xl">
        <AlertDialogHeader>
          <AlertDialogTitle>Edit Text</AlertDialogTitle>
          <AlertDialogDescription className="sr-only">Edit Text Modal</AlertDialogDescription>
        </AlertDialogHeader>

        <UploadRagText
          ragText={{ ...ragText, tagIds: (ragText.tags ?? []).map((i) => i.tagId.toString()) }}
          updateRagText={true}
          closeDialog={closeDialog}
        />
      </AlertDialogContent>
    </AlertDialog>
  )
}

const DeleteDialog: React.FC<ComponentProps<typeof Dialog> & { ragText: AddRagText }> = ({ ragText, ...props }) => {
  const queryClient = useQueryClient()
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async () => {
    try {
      setSubmitting(true)
      if (ragText.docID) {
        await deleteRagText(ragText.docID.toString())
      } else {
        throw new Error('Document ID is undefined.')
      }
      setError('')
      props.onOpenChange?.(false)
    } catch (e) {
      if (e instanceof Error) {
        console.log(e)
        setError(e.message)
      }
    } finally {
      setSubmitting(false)
      queryClient.invalidateQueries({ queryKey: [getRagTextsQueryKey], exact: false })
    }
  }

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete Text</DialogTitle>
          <DialogDescription className="sr-only">Delete Text Modal</DialogDescription>
        </DialogHeader>
        <p>Are you sure you want to delete this text? This action cannot be undone.</p>
        {error && <p className="mt-[0.35rem] text-destructive">{error}</p>}
        <DialogFooter>
          <Button variant="outline" onClick={() => props.onOpenChange?.(false)}>
            Cancel
          </Button>
          <Button variant="destructive" disabled={submitting} onClick={onSubmit}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
