import { Typography } from '@repo/ui/components/typography'

export function HelpPage() {
  return (
    <div className="flex h-full min-h-[760px] w-full bg-cover bg-center">
      <div className="flex flex-col items-start justify-start space-y-4 p-14">
        <Typography size={'h1'} slot="h1">
          Help Page
        </Typography>
      </div>
    </div>
  )
}
