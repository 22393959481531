import { apiErrorHandler } from '@/src/api/errorHandler'
import { parseDataOfType } from '@/src/common/parseDataType'
import { z } from 'zod'

export const getTagsQueryKey = 'getTags'

export const GetTagSchema = z
  .object({
    tagId: z.number(),
    name: z.string(),
    description: z.string().optional(),
    colour: z.string()
  })
  .describe('Tags')

export type Tag = z.infer<typeof GetTagSchema>

export async function getTags(): Promise<Tag[]> {
  const res = await fetch(`/api/tags`, {
    method: 'GET',
    credentials: 'same-origin'
  })

  await apiErrorHandler(res)
  const data = await res.json()
  return parseDataOfType(data, z.array(GetTagSchema).describe('Tags'))
}

export async function saveTag(tag: Partial<Tag>, tagId?: number) {
  const method = tagId ? 'PATCH' : 'POST'
  const endpoint = tagId ? `/api/tags/${tagId}` : '/api/tags'

  const res = await fetch(endpoint, {
    method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(tagId ? { ...tag, id: tagId } : tag)
  })

  await apiErrorHandler(res)
}
export async function deleteTag(tagId: number) {
  const method = 'DELETE'
  const endpoint = `/api/tags/${tagId}`

  const res = await fetch(endpoint, {
    method,
    credentials: 'same-origin'
  })

  await apiErrorHandler(res)
}
