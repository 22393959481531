import { apiErrorHandler } from '@/src/api/errorHandler'
import { parseDataOfType } from '@/src/common/parseDataType'
import { Button } from '@repo/ui/components/button'
import { Input } from '@repo/ui/components/input'
import { Textarea } from '@repo/ui/components/textarea'
import { Typography } from '@repo/ui/components/typography'
import { useQueryClient } from '@tanstack/react-query'
import CRC32 from 'crc-32'
import { useState } from 'react'
import { z } from 'zod'
import { getRagFilesQueryKey } from './storedFiles'

type RagAddFileRequest = {
  description: string
  fileName: string
  crc32Checksum: number
}

const ragAddFileResponseSchema = z
  .object({
    fileID: z.number(),
    preSignedUrl: z.string()
  })
  .describe('RagAddFileResponse')
type RagAddFileResponse = z.infer<typeof ragAddFileResponseSchema>

async function addRagFile(ragAddFileRequest: RagAddFileRequest): Promise<RagAddFileResponse> {
  const res = await fetch(`${window.location.origin}/api/rag/file`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify(ragAddFileRequest)
  })
  await apiErrorHandler(res)

  const data = await res.json()
  return parseDataOfType(data, ragAddFileResponseSchema)
}

async function uploadRagFile(preSignedUrl: string, file: File) {
  // const res = await fetch(preSignedUrl, {
  //   method: 'PUT',
  //   body: file
  // })
  // if (!res.ok) {
  //   throw new Error('Failed to upload file')
  // }
  console.log('uploadRagFile', preSignedUrl, file)
}

async function ragFileUploaded(fileID: number) {
  // const res = await fetch(`${window.location.origin}/api/rag/file/${fileID}/uploaded`, {
  //   method: 'POST',
  //   credentials: 'same-origin'
  // })
  // await apiErrorHandler(res)
  console.log('ragFileUploaded', fileID)
}

export function UploadRagFile() {
  const queryClient = useQueryClient()
  const [error, setError] = useState('')

  const [submitting, setSubmitting] = useState(false)
  const [description, setDescription] = useState('')
  const [file, setFile] = useState<File>(new File([], ''))
  const [fileUpload, setFileUpload] = useState(false)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0])
    }
  }

  const onSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    setError('')
    if (description.length == 0) {
      setError('Description cannot be empty')
      return
    }
    if (file.name.length == 0) {
      setError('Please select a file to upload')
      return
    }

    try {
      setSubmitting(true)

      const fileBytes = new Uint8Array(await file.arrayBuffer())
      // crc-32 package returns a signed int32 (for performance reasons) but crc32 standard requires unsigned int32 (as expected by AWS S3)
      // See: https://github.com/SheetJS/js-crc32?tab=readme-ov-file#signed-integers
      const crc32SignedInt32 = CRC32.buf(fileBytes, 0)
      const crc32Uint32 = crc32SignedInt32 >>> 0
      // console.log('crc32Uint32', crc32Uint32)

      const req: RagAddFileRequest = {
        // Description is encoded to preserve formatting and special characters.
        description: encodeURIComponent(description),
        fileName: file.name,
        crc32Checksum: crc32Uint32
      }
      const addRes = await addRagFile(req)

      // const formData = new FormData()
      // formData.append('file', file)
      // formData.append('fileName', file.name)
      // formData.append('description', description)
      // console.log('formData', formData)

      await uploadRagFile(addRes.preSignedUrl, file)

      await ragFileUploaded(addRes.fileID)

      // setDescription('')
      // setFileName('')
      setFileUpload(true)
    } catch (e) {
      if (e instanceof Error) {
        console.log(e)
        setError(e.message)
      }
    } finally {
      setSubmitting(false)
      queryClient.invalidateQueries({ queryKey: [getRagFilesQueryKey], exact: false })
    }
  }

  return (
    <div className="flex flex-col space-y-8">
      <Typography margin="off">Upload a file to your organisation's secure content vault.</Typography>
      <Textarea
        rows={5}
        placeholder="Enter description of the file"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        disabled={submitting}
      ></Textarea>
      <Input
        className="w-auto min-w-[10rem] mt-0.5"
        id="ragFile"
        type="file"
        accept=".doc"
        onChange={handleFileChange}
        disabled={submitting}
      ></Input>

      <div className="flex flex-row justify-end space-x-4">
        <Button size="default" align="start" onClick={onSubmit} disabled={submitting}>
          Upload
        </Button>
        {error && <p className="mt-[0.35rem] text-destructive">{error}</p>}
      </div>
      {fileUpload && (
        <Typography margin="off" className="text-success">
          File uploaded successfully!
        </Typography>
      )}
    </div>
  )
}
