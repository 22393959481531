import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from '@repo/ui/components/breadcrumb'
import { Button } from '@repo/ui/components/button'
import { SnowvaultArrowHeadIcon, SnowvaultChatIcon } from '@repo/ui/components/icons'
import { Input } from '@repo/ui/components/input'
import { Label } from '@repo/ui/components/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@repo/ui/components/select'
import { Separator } from '@repo/ui/components/separator'
import { SidebarTrigger } from '@repo/ui/components/sidebar'
import { Typography } from '@repo/ui/components/typography'
import { useQuery } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'

import MarkdownRenderer from '@/src/components/markdownRenderer'
import { Fragment, useLayoutEffect, useRef, useState } from 'react'
import { z, ZodError } from 'zod'
import { cn } from '../../../lib/utils'
import { apiErrorHandler } from '../../api/errorHandler'
import { useAuth } from '../../authentication'
import { parseDataOfType } from '../../common/parseDataType'
import { FetchEventTarget, SseMessageTypeData } from '../../common/ssEventStream'

const minModelTemp = 0.0000000001
const ragQuerySearchAgentSchema = z
  .object({
    search: z.string().trim().min(1, 'Search query cannot be empty'),
    llmModel: z.string().optional(),
    modelTemp: z
      .number()
      .min(minModelTemp, `Min temperature is ${minModelTemp}`)
      .max(1, 'Max temperature is 1')
      .optional(),
    promptVersion: z.number().min(1, 'Min prompt version is 1').optional()
  })
  .describe('RagQuerySearchAgent')
type RagQuerySearchAgent = z.infer<typeof ragQuerySearchAgentSchema>

const ragSearchAgentResponseSchema = z
  .object({
    response: z.string()
  })
  .describe('RagSearchAgentResponse')
// type RagSearchAgentResponse = z.infer<typeof ragSearchAgentResponseSchema>

const searchAgentModelsSchema = z
  .object({
    defaultModel: z.string(),
    defaultPromptVersion: z.number(),
    models: z.array(
      z.object({
        model: z.string(),
        defaultTemperature: z.number()
      })
    )
  })
  .describe('SearchAgentModelConfigs')
type SearchAgentModelConfigs = z.infer<typeof searchAgentModelsSchema>

async function getSearchAgentModelConfigs(): Promise<SearchAgentModelConfigs> {
  const res = await fetch(`${window.location.origin}/api/rag/searchAgent/dev/modelConfigs`, {
    credentials: 'same-origin'
  })
  await apiErrorHandler(res)

  const data = await res.json()
  return parseDataOfType(data, searchAgentModelsSchema)
}

export function DashboardPage() {
  const { user } = useAuth()

  const { data, isError, isLoading, error } = useQuery({
    queryKey: ['searchAgentModelConfigs'],
    queryFn: () => getSearchAgentModelConfigs(),
    enabled: user?.enabled_features.search_agent_dev ?? false
  })

  if (isLoading) {
    return <Loader2 className="m-auto" />
  }

  if (isError) {
    console.log('Error getting SearchAgentModelConfigs:', error)
  }

  return (
    <>
      <header className="flex h-14 shrink-0 items-center gap-2 bg-white z-10">
        <div className="flex flex-1 items-center gap-2 ">
          <SidebarTrigger />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage className="line-clamp-1">Overview</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <SearchAgentFrom searchAgentModelConfigs={isError ? undefined : data} />

      {/* <StreamedData /> */}
    </>
  )
}

function SearchAgentFrom({
  searchAgentModelConfigs
}: {
  searchAgentModelConfigs: SearchAgentModelConfigs | undefined
}) {
  // Hooks
  const { user } = useAuth()

  // Local States
  const [submitting, setSubmitting] = useState(false)
  const [searchResponses, setSearchResponses] = useState<{ id: string; question: string; answer: string }[]>([])
  const [search, setSearch] = useState('')
  const [llmModel, setLlmModel] = useState(searchAgentModelConfigs?.defaultModel ?? '')
  const [modelTemp, setModelTemp] = useState(
    () =>
      searchAgentModelConfigs?.models.find((m) => m.model === searchAgentModelConfigs?.defaultModel)
        ?.defaultTemperature ?? 0.5
  )
  const [promptVersion, setPromptVersion] = useState(searchAgentModelConfigs?.defaultPromptVersion ?? 1)

  // Refs
  const mounted = useRef(false)
  const formRef = useRef<HTMLFormElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const conversationRef = useRef<HTMLDivElement>(null)

  const scrollToBottom = (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      element.scrollTop = element.scrollHeight
    }
  }

  // Functions
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitting(true)
    const id = btoa(Math.random().toString()).substring(0, 32)
    try {
      const query: RagQuerySearchAgent = { search }
      const devEnabled = user?.enabled_features.email_agent_dev ?? false
      if (devEnabled) {
        query.llmModel = llmModel
        query.modelTemp = modelTemp
        query.promptVersion = promptVersion
      }
      const searchAgentQuery = ragQuerySearchAgentSchema.parse(query)
      setSearchResponses((prev) => [...prev, { id, question: search, answer: '' }])

      let url = `${window.location.origin}/api/rag/searchAgent`
      if (devEnabled) {
        url += '/dev'
      }

      // const abortController = new AbortController()

      const eventTarget = FetchEventTarget(url, {
        method: 'POST',
        headers: new Headers({
          'content-type': 'application/json'
        }),
        mode: 'same-origin',
        // signal: abortController.signal,
        body: JSON.stringify(searchAgentQuery)
      })
      eventTarget.addEventListener(SseMessageTypeData, (event) => {
        const msg = event as MessageEvent<string>
        const data = parseDataOfType(JSON.parse(msg.data), ragSearchAgentResponseSchema)
        setSearchResponses((prev) => {
          return prev.map((r) => {
            if (r.id === id) {
              return { ...r, answer: r.answer + data.response }
            }
            return r
          })
        })
        scrollToBottom('conversation')
      })
      eventTarget.addEventListener('error', (event) => {
        throw new Error((event as CustomEvent).detail)
      })

      formRef.current?.reset()
      textAreaRef.current!.style.height = 'auto'
      setSearch('')
    } catch (err) {
      setSubmitting(false)
      if (err instanceof ZodError) {
        setSearchResponses((prev) =>
          prev.map((r) => {
            if (r.id === id) {
              return { ...r, answer: err.errors[0].message }
            }
            return r
          })
        )
        return
      }
      if (err instanceof Error) {
        setSearchResponses((prev) =>
          prev.map((r) => {
            if (r.id === id) {
              return { ...r, answer: err.message }
            }
            return r
          })
        )
        return
      }
      throw err
    } finally {
      setSubmitting(false)
    }
  }

  const copyQuestionToField = (e: React.MouseEvent<HTMLSpanElement>) => {
    const question = e.currentTarget.textContent
    if (question) {
      textAreaRef.current!.value = question
      textAreaRef.current!.style.height = 'auto'
      setSearch(question)
    }
  }

  // Effects
  useLayoutEffect(() => {
    if (conversationRef.current && !mounted.current) {
      mounted.current = true
      // Options for the observer (which mutations to observe)
      const config = { attributes: false, childList: true, subtree: true }

      // Callback function to execute when mutations are observed
      const callback = () => {
        if (conversationRef.current) {
          conversationRef.current.scrollTop = conversationRef.current.scrollHeight
        }
      }

      // Create an observer instance linked to the callback function
      const observer = new MutationObserver(callback)

      // Start observing the target node for configured mutations
      observer.observe(conversationRef.current, config)
    }
  }, [])

  return (
    <div
      className={cn(
        'container flex flex-col flex-1 h-full mx-auto space-y-4 pt-12 pb-4 lg:max-w-[1024px] absolute transform left-[0] right-[0]',
        'duration-700 fade-in slide-in-from-top top-0'
      )}
    >
      <div className="flex flex-col space-y-4 h-full mt-[0.7em]">
        {/* CONVERSATION */}
        <div
          id="conversation"
          className={cn(
            'row-start-3 space-y-8 h-full overflow-y-auto px-4 scroll-smooth',
            user?.enabled_features.search_agent_dev ? 'max-h-[75vh]' : 'max-h-[80vh]'
          )}
          ref={conversationRef}
        >
          <div className="grid grid-cols-[auto_1fr] gap-3">
            <SnowvaultChatIcon className="size-7" />
            <div>
              <Typography>Snowvault</Typography>
              <div className="flex flex-col items-start">
                <Typography className="rounded-md bg-[#F8F8F8] p-4 inline-block text-sm opacity-0 !duration-800 !delay-300 animate-appear fade-in fill-mode-forwards">
                  Hello, <strong>{user?.first_name}</strong>
                  <br />
                  We've secured your data in Snowvault.
                  <br />
                  Now harness the power of GenAI on your organisation's data in a safe and secure manner.
                </Typography>
              </div>
            </div>
          </div>

          {searchResponses.map((response) => {
            const isLastResponse = response === searchResponses[searchResponses.length - 1]
            const showLoadingDot = submitting && isLastResponse

            return (
              <Fragment key={response.id}>
                <div className="grid-cols-[auto_1fr] gap-3 duration-300 animate-in fade-in flex justify-end">
                  <div className="flex flex-col items-end">
                    <div className="invisible">{user?.first_name}</div>
                    <Typography
                      onClick={copyQuestionToField}
                      className="cursor-pointer rounded-md bg-[#F8F8F8] p-4 inline-block"
                    >
                      {response.question}
                    </Typography>
                  </div>
                  <span className="flex size-7 items-center justify-center rounded-full bg-brand uppercase text-white">
                    {user?.first_name[0]}
                  </span>
                </div>
                <div className="grid grid-cols-[auto_1fr] gap-3 duration-300 animate-in fade-in">
                  <SnowvaultChatIcon className="size-7" />
                  <div>
                    <Typography>Snowvault</Typography>
                    {showLoadingDot ? (
                      <Loader2 className="animate-spin" />
                    ) : (
                      <MarkdownRenderer>{response.answer}</MarkdownRenderer>
                    )}
                  </div>
                </div>
              </Fragment>
            )
          })}
        </div>
      </div>

      {/* INPUT FIELDS */}
      <div className="flex flex-col space-y-4 px-4">
        <form onSubmit={onSubmit} ref={formRef}>
          <div
            className={cn(
              'relative flex flex-col items-center justify-center gap-4 duration-700 fade-in slide-in-from-bottom sm:flex-row sm:gap-0',
              submitting && 'bg-slate-50'
            )}
          >
            <textarea
              name="search"
              ref={textAreaRef}
              placeholder="How can Snowvault help you today?"
              rows={2}
              className="relative w-full resize-none text-sm rounded-lg border border-solid border-border px-3 py-4 focus:outline-none disabled:bg-transparent"
              disabled={submitting}
              onInput={(e) => {
                const target = e.currentTarget
                target.style.height = 'auto'
                target.style.height = `${target.scrollHeight}px`
                setSearch(target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault()
                  const form = e.currentTarget.form
                  if (form) form.requestSubmit()
                }
              }}
            />
            <Button
              variant="ghost"
              size="icon"
              type="submit"
              className="self-end absolute right-2 bottom-2"
              disabled={submitting}
            >
              {submitting ? <Loader2 className="mr-2 animate-spin" /> : <SnowvaultArrowHeadIcon className="!size-6" />}
            </Button>
          </div>
          <div className="flex gap-4 mt-2 space-y-2 flex-col sm:flex-col md:flex-row md:space-y-0 justify-end">
            {user?.enabled_features.search_agent_dev && (
              <>
                <div className="flex flex-col">
                  <Label className="font-bold text-xs mb-1">LLM Model</Label>
                  <Select
                    name="llmModel"
                    value={llmModel}
                    onValueChange={(value) => {
                      setLlmModel(value)
                      setModelTemp(
                        searchAgentModelConfigs?.models.find((m) => m.model === value)?.defaultTemperature ?? 0.5
                      )
                    }}
                    disabled={submitting}
                  >
                    <SelectTrigger className="w-auto">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>LLM Model</SelectLabel>
                        {searchAgentModelConfigs?.models.map(({ model }) => (
                          <SelectItem key={model} value={model}>
                            {model}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex flex-col">
                  <Label className="font-bold text-xs mb-1 min-w-[7rem]">Model Temp</Label>
                  <Input
                    name="modelTemp"
                    className="w-auto min-w-[10rem] mt-0.5"
                    type="number"
                    min={0}
                    max={1}
                    step={0.0000001}
                    id="modelTemp"
                    placeholder=">0 to 1"
                    value={modelTemp}
                    onChange={(e) => setModelTemp(Number(e.target.value))}
                    disabled={submitting}
                  ></Input>
                </div>
                <div className="flex flex-col">
                  <Label className="font-bold text-xs mb-1 min-w-[7rem]">Prompt Version</Label>
                  <Input
                    name="promptVersion"
                    className="w-full mt-0.5"
                    type="number"
                    min={1}
                    step={1}
                    id="promptVersion"
                    placeholder="1 or greater"
                    value={promptVersion}
                    onChange={(e) => setPromptVersion(Number(e.target.value))}
                    disabled={submitting}
                  ></Input>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
