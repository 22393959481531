import { Skeleton } from '@repo/ui/components/skeleton'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@repo/ui/components/table'
import { useQuery } from '@tanstack/react-query'
import { Trash2 } from 'lucide-react'
import React, { useState } from 'react'
import { DeleteTagDialog } from './deleteTagDialog'
import { TagForm } from './tag-form'
import { getTags, getTagsQueryKey, Tag } from './tagService'

export const TagList = React.memo(() => {
  const { data: tags, isLoading } = useQuery<Tag[]>({
    queryKey: [getTagsQueryKey],
    queryFn: getTags
  })
  const [showDelete, setShowDelete] = useState(false)
  const [selectedTagId, setSelectedTagId] = useState<number | null>(null)
  const handleDeleteClick = (tagId: number) => {
    setSelectedTagId(tagId)
    setShowDelete(true)
  }
  return (
    <div className="w-full animate-appear space-y-8 opacity-0">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[24em]">Name</TableHead>
            <TableHead className="w-full">Description</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <>
              {[...Array(2)].map((_, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <Skeleton className="h-[24px] rounded-full m-2 w-full" />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            tags?.map((tag) => (
              <TableRow key={tag.tagId}>
                <TableCell>
                  <span className="p-2 rounded-[15px] w-fit text-center" style={{ backgroundColor: tag.colour }}>
                    {tag.name}
                  </span>
                </TableCell>
                <TableCell className="whitespace-pre-wrap">{tag.description}</TableCell>
                <TableCell>
                  <TagForm updateTag tagForm={tag} tagId={tag.tagId} />
                </TableCell>
                <TableCell>
                  <Trash2 size={18} onClick={() => handleDeleteClick(tag.tagId)} style={{ cursor: 'pointer' }} />
                  {showDelete && selectedTagId && (
                    <DeleteTagDialog open={showDelete} onOpenChange={setShowDelete} tagId={selectedTagId} />
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </div>
  )
})
