// import AuthProvider from "react-auth-kit";
import { SidebarProvider } from '@repo/ui/components/sidebar'
import { Toaster } from '@repo/ui/components/toaster'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Navigate, Route, Routes } from 'react-router'
import { AuthProvider, RequireAuth } from './authentication'
import { AuthenticatedLayout } from './layouts/authenticated'
import CentredLayout from './layouts/centred'
import { ContentManagementPage } from './routes/content-management/contentManagement'
import { DashboardPage } from './routes/dashboard/dashboard'
import { EmailAgentConceptPage } from './routes/email-agent-concept/emailAgentConcept'
import { EmailAgentPage } from './routes/email-agent/emailAgent'
import { HelpPage } from './routes/help/help'
import { LoginPage } from './routes/login/login'
import { PageNotFound } from './routes/not-found'
import { UserPasswordReset } from './routes/password-reset/password-reset'
import { ScriptAgentPage } from './routes/script-agent/scriptAgent'
import SettingsOrganisationPage from './routes/settings/_components/organisation/page'
import SettingsProfilePage from './routes/settings/_components/profile/page'
import SettingsTagsPage from './routes/settings/_components/tags/page'
import SettingsUsersPage from './routes/settings/_components/users/page'
import { SettingsLayout } from './routes/settings/settings'
import { StoryboardAgentDemoPage } from './routes/storyboard-agent/storyboard-agent'
import { UserVerify } from './routes/user-verify/user-verify'
// const store = createStore({
//   authName: "_auth",
//   authType: "cookie",
//   cookieDomain: window.location.hostname,
//   cookieSecure: window.location.protocol === "https:",
// });

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Toaster />
        <Routes>
          <Route path="" element={<Navigate to="app/login" />} />
          <Route path="app">
            <Route
              element={
                <RequireAuth>
                  <SidebarProvider>
                    <AuthenticatedLayout />
                  </SidebarProvider>
                </RequireAuth>
              }
            >
              <Route index element={<DashboardPage />} />
              <Route path="content-management" element={<ContentManagementPage />} />
              <Route path="email-agent" element={<EmailAgentPage />} />
              <Route path="email-agent-concept" element={<EmailAgentConceptPage />} />
              <Route path="script-agent" element={<ScriptAgentPage />} />
              <Route path="storyboard-agent-demo" element={<StoryboardAgentDemoPage />} />
              <Route path="settings" element={<SettingsLayout />}>
                <Route path="profile" element={<SettingsProfilePage />} />
                <Route path="organisation" element={<SettingsOrganisationPage />} />
                <Route path="users" element={<SettingsUsersPage />} />
                <Route path="tags" element={<SettingsTagsPage />} />
              </Route>
              <Route path="help" element={<HelpPage />} />
            </Route>

            <Route element={<CentredLayout />}>
              <Route path="404" element={<PageNotFound />} />
              <Route path="login" element={<LoginPage />} />
              <Route path="user">
                <Route path="verify" element={<UserVerify />} />
                <Route path="password-reset" element={<UserPasswordReset />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
