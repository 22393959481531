import { FetchEventTarget, SseMessageTypeData } from '@/src/common/ssEventStream'
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from '@repo/ui/components/breadcrumb'
import { Button } from '@repo/ui/components/button'
import { Separator } from '@repo/ui/components/separator'
import { SidebarTrigger } from '@repo/ui/components/sidebar'
import { Textarea } from '@repo/ui/components/textarea'
import { Typography } from '@repo/ui/components/typography'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import { z } from 'zod'
import { parseDataOfType } from '../../common/parseDataType'

type RagQueryScriptAgent = {
  scriptBrief: string
}

const ragScriptAgentResponseSchema = z
  .object({
    response: z.string()
  })
  .describe('RagScriptAgentResponse')
// type RagScriptAgentResponse = z.infer<typeof ragScriptAgentResponseSchema>

export function ScriptAgentPage() {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [scriptBrief, setScriptBrief] = useState('')
  const [scriptResponse, setScriptResponse] = useState('')

  const onSubmit = async () => {
    if (scriptBrief === '') {
      setError('Script brief cannot be empty')
      return
    }
    setError('')
    setSubmitting(true)

    const queryScript: RagQueryScriptAgent = { scriptBrief: scriptBrief }
    try {
      const eventTarget = FetchEventTarget(`${window.location.origin}/api/rag/scriptAgent`, {
        method: 'POST',
        headers: new Headers({
          'content-type': 'application/json'
        }),
        mode: 'same-origin',
        // signal: abortController.signal,
        body: JSON.stringify(queryScript)
      })
      eventTarget.addEventListener(SseMessageTypeData, (event) => {
        const msg = event as MessageEvent<string>
        const data = parseDataOfType(JSON.parse(msg.data), ragScriptAgentResponseSchema)
        setScriptResponse((prev) => prev + data.response)
        const el = document.getElementById('scriptResponse')
        if (el) {
          el.style.height = 'auto'
          el.style.height = el.scrollHeight + 5 + 'px'
        }
      })
      eventTarget.addEventListener('error', (event) => {
        throw new Error((event as CustomEvent).detail)
      })
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
        return
      }
      throw err
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <>
      <header className="flex h-14 shrink-0 items-center gap-2">
        <div className="flex flex-1 items-center gap-2 ">
          <SidebarTrigger />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage className="line-clamp-1">Script Agent</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="space-y-4 px-4 py-2">
        <Textarea
          id="scriptBrief"
          rows={10}
          placeholder="Enter your script brief here..."
          disabled={submitting}
          value={scriptBrief}
          onChange={(e) => {
            setScriptBrief(e.target.value)
            const el = document.getElementById('scriptBrief')
            if (el) {
              el.style.height = 'auto'
              el.style.height = el.scrollHeight + 5 + 'px'
            }
          }}
        ></Textarea>
        <Button
          variant="brand"
          size="default"
          // align="end"
          onClick={onSubmit}
          disabled={submitting}
        >
          {submitting && <Loader2 className="mr-2 animate-spin" />}Generate Script
        </Button>
        {error && <Typography className="text-destructive">{error}</Typography>}
        <Textarea id="scriptResponse" rows={10} placeholder="" readOnly={true} value={scriptResponse}></Textarea>
      </div>
    </>
  )
}
