import { Button } from '@repo/ui/components/button'
import { Textarea } from '@repo/ui/components/textarea'
import { Typography } from '@repo/ui/components/typography'
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage } from '@repo/ui/components/breadcrumb'
import { Separator } from '@repo/ui/components/separator'
import { SidebarTrigger } from '@repo/ui/components/sidebar'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'

const initialScript = `Write  a script for a 60 second video helping make people aware of the danger of the shot hold borer battle.  
Show people in Hyde Park Perth having picnics at Christmas.
Invoke a tone of voice of concern for the environment.
Include 6 scenes
`

const generatedScript = `**Scene 1: Opening shot**
(Upbeat background music starts playing. The camera pans over a serene landscape of Hyde Park in Perth, showing people having picnics and enjoying the Christmas atmosphere.)
Narrator (in a concerned tone): "Imagine spending Christmas surrounded by friends and family, with the sun shining down on you... "

**Scene 2: Close-up**
(The camera zooms in on a person taking a bite of food, unaware of the danger lurking nearby.)
Narrator: "But for our trees, that's not a scene we want to imagine."

**Scene 3: Introduction to PSHB**
(Visuals of the polyphagous shot-hole borer beetle appear on screen, with a red "X" marked through it.)
Narrator: "Meet the Polyphagous Shot-Hole Borer (PSHB), a tiny but deadly pest that's spreading rapidly across our city."

**Scene 4: Infestation signs**
(The camera shows people in Hyde Park noticing small holes or dark stains on tree trunks and branches.)
Narrator: "If you notice small holes, discoloration, or staining on trees, it could be a sign of PSHB infestation. Don't move untreated wood - this can spread the disease."

**Scene 5: Action**
(Visuals of people taking action: pruning infected trees, planting new saplings, and using proper biosecurity protocols.)
Narrator: "Act now! Prune or remove affected trees, and replant with healthy ones to protect our tree canopy. Every small action counts in safeguarding WA's biosecurity."

**Scene 6: Call to action**
(The camera pans out to show the Hyde Park landscape again, but this time with a sense of urgency and concern.)
Narrator: "Let's act fast to protect our environment and keep Western Australia safe. Report any signs and learn more at wa.gov.au/borer."`

const storyBoardFrames: StoryBoardFrameProps[] = [
  {
    title: 'Scene 1: Opening shot',
    description:
      'Upbeat background music starts playing. The camera pans over a serene landscape of Hyde Park in Perth, showing people having picnics and enjoying the Christmas atmosphere.',
    imageUrl: '/app/images/scene_1.png',
    updatedImageUrl: '/app/images/scene_1_2.png',
    narration:
      '(in a concerned tone): Imagine spending Christmas surrounded by friends and family, with the sun shining down on you... '
  },
  {
    title: 'Scene 2: Close-up',
    description: 'The camera zooms in on a person taking a bite of food, unaware of the danger lurking nearby.',
    imageUrl: '/app/images/scene_2.png',
    narration: "But for our trees, that's not a scene we want to imagine."
  },
  {
    title: 'Scene 3: Introduction to PSHB',
    description:
      "Visuals of the polyphagous shot-hole borer beetle appear on screen, with a red 'X' marked through it.",
    imageUrl: '/app/images/scene_3.png',
    narration:
      "Meet the Polyphagous Shot-Hole Borer (PSHB), a tiny but deadly pest that's spreading rapidly across our city."
  },
  {
    title: 'Scene 4: Infestation signs',
    description:
      'The camera shows people in Hyde Park noticing small holes or dark stains on tree trunks and branches.',
    imageUrl: '/app/images/scene_4.png',
    narration:
      "If you notice small holes, discolouration, or staining on trees, it could be a sign of PSHB infestation. Don't move untreated wood - this can spread the disease."
  },
  {
    title: 'Scene 5: Action',
    description:
      'Visuals of people taking action: pruning infected trees, planting new saplings, and using proper biosecurity protocols.',
    imageUrl: '/app/images/scene_5.png',
    narration:
      "Act now! Prune or remove affected trees, and replant with healthy ones to protect our tree canopy. Every small action counts in safeguarding WA's biosecurity."
  },
  {
    title: 'Scene 6: Call to action',
    description:
      'The camera pans out to show the Hyde Park landscape again, but this time with a sense of urgency and concern.',
    imageUrl: '/app/images/scene_6.png',
    narration: "Let's act fast to protect our environment and keep Western Australia safe. Report any signs"
  }
]

export function StoryboardAgentDemoPage() {
  const [generatingScript, setGeneratingScript] = useState(false)
  const [generatingStoryboard, setGeneratingStoryboard] = useState(false)
  const [scriptBrief, setScriptBrief] = useState(initialScript)
  const [scriptResponse, setScriptResponse] = useState('')
  const [storyboardHidden, setStoryboardHidden] = useState(true)
  const [exportingPDF, setExportingPDF] = useState(false)

  const onSubmitScript = async () => {
    setGeneratingScript(true)
    setTimeout(() => {
      setScriptResponse(generatedScript)
      setGeneratingScript(false)
    }, 3000)
  }

  const onSubmitStoryboard = async () => {
    setGeneratingStoryboard(true)
    setTimeout(() => {
      setStoryboardHidden(false)
      setGeneratingStoryboard(false)
    }, 3000)
  }

  const onExportPDF = async () => {
    setExportingPDF(true)
    setTimeout(() => {
      setExportingPDF(false)
    }, 3000)
  }

  return (
    <>
      <header className="flex h-14 shrink-0 items-center gap-2">
        <div className="flex flex-1 items-center gap-2 ">
          <SidebarTrigger />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage className="line-clamp-1">Storyboard Agent</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="space-y-4 px-4 py-2">
        <Textarea
          rows={10}
          placeholder="Enter your script brief here..."
          disabled={generatingScript}
          value={scriptBrief}
          onChange={(e) => setScriptBrief(e.target.value)}
        ></Textarea>
        <Button variant="brand" size="default" onClick={onSubmitScript} disabled={generatingScript}>
          {generatingScript && <Loader2 className="mr-2 animate-spin" />}Generate Script
        </Button>
        <Textarea
          rows={10}
          placeholder=""
          // readOnly={true}
          disabled={generatingStoryboard}
          value={scriptResponse}
          onChange={(e) => setScriptResponse(e.target.value)}
        ></Textarea>
        <div className='flex flex-col gap-4'>
          <Button
            variant="brand"
            size="default"
            className='w-min'
            onClick={onSubmitStoryboard}
            disabled={generatingStoryboard || scriptResponse === ''}
          >
            {generatingStoryboard && <Loader2 className="mr-2 animate-spin" />}Generate Storyboard
          </Button>
          <div
            className={`@apply grid grid-flow-row grid-cols-[1fr_1fr_1fr] grid-rows-[1fr_1fr] gap-[0px_0px] [&>div]:border [&>div]:border-[#e3e9f0] ${storyboardHidden ? 'hidden' : 'grid'}`}
          >
            {storyBoardFrames.map((frame, i) => (
              <StoryBoardFrame key={i} {...frame} />
            ))}
          </div>
          <Button variant="brand" className='w-min' size="default" onClick={onExportPDF} disabled={exportingPDF || storyboardHidden}>
            {exportingPDF && <Loader2 className="mr-2 animate-spin" />}Save & Export PDF
          </Button>
        </div>
      </div>
    </>
  )
}

type StoryBoardFrameProps = {
  title: string
  description: string
  imageUrl: string
  updatedImageUrl?: string
  narration: string
}

function StoryBoardFrame(props: StoryBoardFrameProps) {
  const [description] = useState(props.description)
  const [updatingImage, setUpdatingImage] = useState(false)
  const [imageUrl, setImageUrl] = useState(props.imageUrl)

  const onUpdateImage = async () => {
    setUpdatingImage(true)
    setTimeout(() => {
      setImageUrl((currentState) =>
        currentState === props.updatedImageUrl ? props.imageUrl : (props.updatedImageUrl ?? props.imageUrl)
      )
      setUpdatingImage(false)
    }, 3000)
  }

  return (
    <div className="`@apply grid grid-flow-row grid-cols-[1] grid-rows-[1fr_2fr_1.5fr_6fr_2fr] gap-[0px_0px] p-5">
      <Typography size="h2" className="text-xs" contentEditable>
        {props.title}
      </Typography>
      <Typography className="text-xs" contentEditable>
        {description}
      </Typography>
      <Button variant="brandLight" size="sm" onClick={onUpdateImage} disabled={updatingImage}>
        {updatingImage && <Loader2 className="mr-2 animate-spin" />}Regenerate Image
      </Button>
      <img
        src={imageUrl}
        alt="image"
        className={`w-full border border-[#e3e9f0] transition-all ${updatingImage ? 'blur-sm' : ''} `}
      ></img>
      <Typography className="text-xs" contentEditable>
        Narrator: "{props.narration}"
      </Typography>
    </div>
  )
}
