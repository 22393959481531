import { Avatar, AvatarFallback } from '@repo/ui/components/avatar'
import { Button } from '@repo/ui/components/button'
import { MilnerCollegeIcon } from '@repo/ui/components/icons'
import { Skeleton } from '@repo/ui/components/skeleton'
import { Typography } from '@repo/ui/components/typography'
import { ArrowRight } from 'lucide-react'
import { cn } from '../../../../../lib/utils'
import { EmailData } from '../../emailAgentConcept'

interface Props {
  previewEmailData?: EmailData
  isLoading: boolean
}

export function EmailPreview({ previewEmailData, isLoading }: Props) {
  return (
    <div data-testid="preview" className="w-full">
      {isLoading ? (
        <PreviewSkeleton />
      ) : (
        previewEmailData && (
          <>
            <div className="flex h-[36x] w-full flex-col items-end gap-2 space-y-4 rounded-none border-none bg-white px-5 py-3 text-left">
              <EmailFromPreview emailFrom={previewEmailData.from} />
            </div>
            <div className="flex h-[36x] w-full flex-col items-end gap-2 space-y-4 rounded-none border-none bg-white px-5 py-3 text-left">
              <ResponsePreview previewEmailData={previewEmailData} />
            </div>
          </>
        )
      )}
    </div>
  )
}

const offsetStyle = 'w-[calc(100%_-30px)]'

function EmailFromPreview({ emailFrom }: { emailFrom: EmailData['from'] }) {
  return (
    <>
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-row space-x-2">
          <Avatar className="h-6 w-6">
            <AvatarFallback className="bg-brand-dark text-white">{emailFrom.name[0]}</AvatarFallback>
          </Avatar>
          <Typography margin="off" className="font-bold text-black">
            {emailFrom.name}
          </Typography>
        </div>
        <Typography margin="off" className="text-xs text-muted-foreground">
          {emailFrom.dateReceived}
        </Typography>
      </div>
      <div className={cn('rounded-sm bg-brand-light p-4', offsetStyle)}>
        <Typography margin="off">
          <strong>From:</strong> {emailFrom.name} &lt;{emailFrom.email}&gt;
        </Typography>
        <Typography margin="off">
          <strong>Subject:</strong> {emailFrom.subject}
        </Typography>
      </div>
    </>
  )
}

function ResponsePreview({ previewEmailData }: { previewEmailData: EmailData }) {
  const isDraft = previewEmailData.state === 'DRAFT'
  const isReplied = previewEmailData.state === 'REPLIED'

  return (
    <>
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-row space-x-2">
          <MilnerCollegeIcon className="h-6 w-6" />
          <Typography margin="off" className="font-bold text-black">
            Milner College
          </Typography>
        </div>
        {isDraft ||
          (isReplied && (
            <Typography margin="off" className="text-xs text-muted-foreground">
              {previewEmailData.response.dateSent}
            </Typography>
          ))}
      </div>
      {isDraft && (
        <div className={cn('!-mb-4 h-[30px] rounded-sm bg-[#2d2b2b] px-4 py-2 text-white', offsetStyle)}>
          <Typography margin="off" variant="secondary" className="text-sm leading-none">
            DRAFT
          </Typography>
        </div>
      )}
      <div className={cn('border-card rounded-sm border bg-white p-4', offsetStyle)}>
        <Typography margin="off">
          <strong>To:</strong> {previewEmailData.from.name} &lt;{previewEmailData.from.email}&gt;
        </Typography>
        <Typography margin="off">
          <strong>Subject:</strong> Re: {previewEmailData.from.subject}
        </Typography>
        {isDraft ||
          (isReplied && (
            <>
              <Typography margin="off" className="mt-4 whitespace-break-spaces text-black text-muted-foreground">
                {previewEmailData.response.bodyContent}
              </Typography>
            </>
          ))}
      </div>
      {isDraft && (
        <Button className="mt-4" variant="brand" size="sm">
          Send <ArrowRight />
        </Button>
      )}
    </>
  )
}

function PreviewSkeleton() {
  return (
    <>
      {/* Enquiry */}
      <div className="flex h-[36x] w-full flex-col items-end gap-2 space-y-4 rounded-none border-none bg-white px-5 py-3 text-left">
        <div className="flex w-full flex-row justify-between">
          <div className="flex flex-row space-x-2">
            <Skeleton className="h-6 min-w-6 rounded-full" />
            <Skeleton className="min-w-40" />
          </div>
          <Skeleton className="min-w-30" />
        </div>
        <div className={cn('rounded-sm bg-gray-50 p-4', offsetStyle)}>
          <Skeleton className="mb-2 h-5 w-56 bg-brand-light" />
          <Skeleton className="h-5 w-20 bg-brand-light" />
        </div>
      </div>

      {/* Response */}
      <div className="flex h-[36x] w-full flex-col items-end gap-2 space-y-4 rounded-none border-none bg-white px-5 py-3 text-left">
        <div className="flex w-full flex-row justify-between">
          <div className="flex flex-row space-x-2">
            <Skeleton className="h-6 min-w-6 rounded-full" />
            <Skeleton className="min-w-40" />
          </div>
        </div>
        <div className={cn('flex flex-col space-y-4 rounded-sm border border-gray-200 bg-white p-4', offsetStyle)}>
          <div >
            <Skeleton className="w-46" />
          </div>
          <div >
            <Skeleton className="w-30" />
          </div>

          <Skeleton className="w-8 bg-brand-light" />
          <br />
          <Skeleton className="w-20 bg-brand-light" />
          <br />
          <Skeleton className="w-20 bg-brand-light" />
          <br />
        </div>
      </div>
    </>
  )
}
