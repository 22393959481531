import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from '@repo/ui/components/breadcrumb'
import { Separator } from '@repo/ui/components/separator'
import { SidebarTrigger } from '@repo/ui/components/sidebar'
import { Typography } from '@repo/ui/components/typography'
import { Outlet } from 'react-router'
import { SidebarNav } from './_components/sidebar-nav'

const sidebarNavItems = [
  {
    title: 'Profile',
    href: '/app/settings/profile'
  },
  {
    title: 'Organisation',
    href: '/app/settings/organisation'
  },
  {
    title: 'Users',
    href: '/app/settings/users'
  },
  {
    title: 'Tags',
    href: '/app/settings/tags'
  }
]

export function SettingsLayout() {
  return (
    <>
      <header className="flex h-14 shrink-0 items-center gap-2">
        <div className="flex flex-1 items-center gap-2 ">
          <SidebarTrigger />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage className="line-clamp-1">Settings</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div className="space-y-4 px-4 py-2">
        <div className="w-full border-b-[1px] border-border-card">
          <Typography margin="off" size={'h1'} slot="h1" className="text-2xl">
            Settings
          </Typography>
          <Typography className="text-1xl text-[#71717a]">
            Manage your account settings and set e-mail preferences.
          </Typography>
        </div>
        <div className="flex w-full flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="-mx-4 lg:w-1/5">
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="w-full flex-1 lg:max-w-2xl">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}
