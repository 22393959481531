import { apiErrorHandler } from '@/src/api/errorHandler'
import { parseDataOfType } from '@/src/common/parseDataType'
import { array, z } from 'zod'
import { GetTagSchema } from '../../settings/_components/tags/tagService'

export const AddRagTextFormSchema = z.object({
  docID: z.number().optional(),
  text: z.string(),
  tagIds: z.array(z.string()).optional()
})
export type AddRagText = z.infer<typeof AddRagTextFormSchema>

export const GetRagTextSchema = z
  .object({
    docID: z.number(),
    text: z.string(),
    updatedAtTsMs: z.number(),
    updatedByName: z.string(),
    tags: array(GetTagSchema).optional()
  })
  .describe('GetRagText')
export type GetRagText = z.infer<typeof GetRagTextSchema>

const ragTextPageSchema = z
  .object({
    items: z.array(GetRagTextSchema),
    pageCount: z.number(),
    currentPage: z.number(),
    nextPageTsMs: z.number(),
    previousPageTsMs: z.number()
  })
  .describe('RagTextPageSchema')
export type RagTextPage = z.infer<typeof ragTextPageSchema>

export async function deleteRagText(id: string) {
  const res = await fetch(`${window.location.origin}/api/rag/text/${id}`, {
    method: 'DELETE',
    credentials: 'same-origin'
  })
  await apiErrorHandler(res)
}

export async function saveRagText(ragAddText: AddRagText, docId?: number) {
  const method = docId ? 'PUT' : 'POST'
  const endpoint = docId ? `/api/rag/text/${docId}` : '/api/rag/text'
  const TagIds = ragAddText.tagIds?.map(Number)
  const res = await fetch(endpoint, {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify({ ...ragAddText, tagIds: TagIds })
  })

  await apiErrorHandler(res)
}

export async function getRagTexts({
  previousPageTsMs,
  nextPageTsMs
}: {
  previousPageTsMs?: number
  nextPageTsMs?: number
}) {
  const params = new URLSearchParams()
  if (nextPageTsMs) {
    params.append('nextPageTsMs', nextPageTsMs.toString())
  } else if (previousPageTsMs) {
    params.append('previousPageTsMs', previousPageTsMs.toString())
  }
  const res = await fetch(`${window.location.origin}/api/rag/text?${params.toString()}`, {
    method: 'GET',
    credentials: 'same-origin'
  })
  await apiErrorHandler(res)

  const data = await res.json()
  const jsonData = parseDataOfType(data, ragTextPageSchema)
  // Texts are encoded to preserve formatting and special characters.
  for (const item of jsonData.items) {
    item.text = decodeURIComponent(item.text)
  }
  return jsonData
}
