export interface LogoProps extends React.SVGProps<SVGSVGElement> {
  variant: 'LIGHT' | 'DARK'
  testId?: string
}
export function Logo({ variant, testId, ...props }: LogoProps) {
  if (variant === 'LIGHT') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="122" height="32" viewBox="0 0 122 32" fill="none" {...props}>
        <path
          d="M110.886 9.46285H121.185C121.346 10.368 121.346 11.2531 121.185 12.1381H117.645V22.5376C116.559 22.7387 115.492 22.7387 114.426 22.5376V12.1381H110.886C110.725 11.2531 110.725 10.3479 110.886 9.46285Z"
          fill="white"
        />
        <path
          d="M105.968 19.8619H111.358C111.519 20.747 111.519 21.6521 111.358 22.5372H102.749V9.46248C103.815 9.28144 104.881 9.28144 105.968 9.46248V19.8619Z"
          fill="white"
        />
        <path
          d="M96.7065 17.5487V9.46248C97.7927 9.28144 98.8789 9.28144 99.9852 9.46248V17.0458C99.9852 20.9079 98.1145 22.8389 94.4134 22.8389C90.6921 22.8389 88.8416 20.9079 88.8416 17.0458V9.46248C89.9278 9.28144 91.014 9.28144 92.1203 9.46248V17.5487C92.1203 18.3332 92.3013 18.9567 92.7036 19.4395C93.1059 19.9222 93.6691 20.1636 94.4134 20.1636C95.1375 20.1636 95.7007 19.9222 96.103 19.4395C96.5053 18.9567 96.7065 18.3332 96.7065 17.5487Z"
          fill="white"
        />
        <path
          d="M81.6834 9.46278L86.9938 22.5375C85.8874 22.7386 84.7811 22.7386 83.6748 22.5375L82.7495 20.0634H77.9621L77.0369 22.5375C75.9104 22.7386 74.8041 22.7386 73.7179 22.5375L79.0282 9.46278C79.9133 9.32198 80.7984 9.32198 81.6834 9.46278ZM78.9679 17.3478H81.7438L80.3558 13.6065L78.9679 17.3478Z"
          fill="white"
        />
        <mask
          id="mask0_1024_1571"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="61"
          y="9"
          width="16"
          height="14"
        >
          <path
            d="M68.4132 22.7235C68.6192 23.0922 69.0475 23.0922 69.2536 22.7235L76.3383 10.0475C76.6214 9.541 76.2504 8.86016 75.7812 9.0252L68.9703 11.421C68.8807 11.4525 68.7861 11.4525 68.6964 11.421L61.8855 9.0252C61.4163 8.86015 61.0453 9.54099 61.3284 10.0475L68.4132 22.7235Z"
            fill="#7D51F5"
          />
        </mask>
        <g mask="url(#mask0_1024_1571)">
          <rect
            width="8.3525"
            height="15.9802"
            transform="matrix(1 1.74846e-07 1.74846e-07 -1 68.8168 23.8059)"
            fill="#A485F8"
          />
          <rect
            width="8.35251"
            height="15.9802"
            transform="matrix(1 1.74846e-07 1.74846e-07 -1 60.4816 23.8059)"
            fill="#BEA8FA"
          />
        </g>
        <path
          d="M60.203 9.46248L55.6972 22.5372C54.8122 22.6981 53.9271 22.6981 53.0421 22.5372L50.5277 14.8131L48.0133 22.5372C47.1283 22.6981 46.2432 22.6981 45.3582 22.5372L40.8524 9.46248C41.9587 9.28144 43.0651 9.28144 44.1714 9.46248L46.6857 17.7498L49.2001 9.46248C50.0852 9.32167 50.9702 9.32167 51.8553 9.46248L54.3697 17.7498L56.884 9.46248C57.9903 9.28144 59.0967 9.28144 60.203 9.46248Z"
          fill="white"
        />
        <path
          d="M32.5548 9.16093C34.5864 9.16093 36.2559 9.82472 37.5433 11.1523C38.8306 12.4799 39.4944 14.0891 39.4944 16C39.4944 17.931 38.8306 19.5402 37.5433 20.8678C36.2559 22.1954 34.5864 22.8391 32.5548 22.8391C30.503 22.8391 28.8335 22.1954 27.5461 20.8678C26.2588 19.5402 25.6151 17.931 25.6151 16C25.6151 14.0891 26.2588 12.4799 27.5461 11.1523C28.8335 9.82472 30.503 9.16093 32.5548 9.16093ZM32.5548 11.8362C31.5289 11.8362 30.664 12.2184 29.9599 12.9828C29.2358 13.7471 28.8938 14.7529 28.8938 16C28.8938 17.2672 29.2358 18.273 29.9599 19.0374C30.664 19.8017 31.5289 20.1638 32.5548 20.1638C33.5605 20.1638 34.4255 19.8017 35.1496 19.0374C35.8536 18.273 36.2157 17.2672 36.2157 16C36.2157 14.7529 35.8536 13.7471 35.1496 12.9828C34.4255 12.2184 33.5605 11.8362 32.5548 11.8362Z"
          fill="white"
        />
        <path
          d="M23.3688 9.46248V22.5372C22.524 22.6981 21.6791 22.6981 20.8544 22.5372L14.7596 14.8734V22.5372C13.6734 22.7383 12.6073 22.7383 11.5412 22.5372V9.46248C12.386 9.32167 13.2107 9.32167 14.0556 9.46248L20.1504 17.1263V9.46248C21.2165 9.28144 22.2826 9.28144 23.3688 9.46248Z"
          fill="white"
        />
        <path
          d="M4.72701 9.16093C5.99425 9.16093 7.20115 9.50288 8.3477 10.1667C8.42816 10.4282 8.46839 10.9713 8.4885 11.7759C8.4885 12.5805 8.44827 13.0632 8.3477 13.2241C6.77873 12.2989 5.57184 11.8362 4.74713 11.8362C4.28448 11.8362 3.92241 11.9569 3.66092 12.1581C3.37931 12.3793 3.25862 12.681 3.25862 13.0431C3.25862 13.4253 3.41954 13.7069 3.74138 13.9081C4.06322 14.1092 4.66666 14.3305 5.59195 14.6121C6.07471 14.773 6.49712 14.954 6.85919 15.115C7.22126 15.2759 7.60345 15.5173 8.00574 15.819C8.40804 16.1207 8.72988 16.523 8.95115 17.0058C9.17241 17.4885 9.2931 18.0517 9.2931 18.6954C9.2931 20.023 8.83046 21.0489 7.92528 21.773C7.02011 22.4971 5.87356 22.8391 4.48563 22.8391C2.9569 22.8391 1.64942 22.5172 0.543103 21.8333C0.462644 21.6121 0.402299 21.0891 0.402299 20.2644C0.382184 19.4397 0.422414 18.9368 0.543103 18.7759C2.11207 19.7012 3.37931 20.1638 4.36494 20.1638C4.90804 20.1638 5.33046 20.0632 5.61207 19.842C5.89368 19.6207 6.03448 19.319 6.03448 18.9368C6.03448 18.5747 5.87356 18.3132 5.57184 18.1121C5.25 17.931 4.60632 17.6695 3.62069 17.3477C3.13793 17.1868 2.73563 17.0259 2.37356 16.8448C2.01149 16.6839 1.62931 16.4425 1.22701 16.1207C0.824712 15.819 0.522988 15.4368 0.321839 14.954C0.100575 14.4914 0 13.9684 0 13.365C0 12.1782 0.402299 11.1724 1.24713 10.3678C2.09195 9.56323 3.2385 9.16093 4.72701 9.16093Z"
          fill="white"
        />
      </svg>
    )
  } else {
    return (
      <svg
        width="125"
        height="14"
        viewBox="0 0 125 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="nav-snowvault-logo"
        {...props}
      >
        <path
          d="M113.719 0.534668H124.22C124.384 1.42989 124.384 2.30522 124.22 3.18056H120.61V13.4657C119.502 13.6646 118.415 13.6646 117.328 13.4657V3.18056H113.719C113.555 2.30522 113.555 1.41 113.719 0.534668Z"
          fill="black"
        />
        <path
          d="M108.703 10.8198H114.2C114.364 11.6951 114.364 12.5904 114.2 13.4657H105.422V0.534674C106.509 0.355629 107.596 0.355629 108.703 0.534674V10.8198Z"
          fill="black"
        />
        <path
          d="M99.2609 8.53202V0.534674C100.368 0.355629 101.476 0.355629 102.604 0.534674V8.03467C102.604 11.8543 100.697 13.7641 96.9228 13.7641C93.1286 13.7641 91.2417 11.8543 91.2417 8.03467V0.534674C92.3492 0.355629 93.4567 0.355629 94.5847 0.534674V8.53202C94.5847 9.30788 94.7693 9.92459 95.1795 10.402C95.5897 10.8795 96.164 11.1182 96.9228 11.1182C97.6612 11.1182 98.2354 10.8795 98.6456 10.402C99.0558 9.92459 99.2609 9.30788 99.2609 8.53202Z"
          fill="black"
        />
        <path
          d="M83.943 0.534618L89.3575 13.4656C88.2295 13.6646 87.1015 13.6646 85.9735 13.4656L85.03 11.0187H80.1488L79.2053 13.4656C78.0568 13.6646 76.9288 13.6646 75.8213 13.4656L81.2358 0.534618C82.1382 0.395362 83.0406 0.395362 83.943 0.534618ZM81.1743 8.33302H84.0046L82.5894 4.63276L81.1743 8.33302Z"
          fill="black"
        />
        <mask
          id="mask0_879_4863"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="63"
          y="0"
          width="16"
          height="14"
        >
          <path
            d="M70.4125 13.6498C70.6226 14.0145 71.0593 14.0145 71.2694 13.6499L78.4931 1.1132C78.7818 0.612239 78.4035 -0.0611203 77.9251 0.102109L70.9805 2.47155C70.8892 2.50273 70.7927 2.50273 70.7013 2.47155L63.7569 0.102106C63.2785 -0.0611229 62.9001 0.612235 63.1888 1.11319L70.4125 13.6498Z"
            fill="#7D51F5"
          />
        </mask>
        <g mask="url(#mask0_879_4863)">
          <rect
            width="8.51632"
            height="15.8045"
            transform="matrix(1 1.69598e-07 1.80256e-07 -1 70.8237 14.7202)"
            fill="#5F35D3"
          />
          <rect
            width="8.51632"
            height="15.8045"
            transform="matrix(1 1.69598e-07 1.80256e-07 -1 62.3252 14.7202)"
            fill="#7851F5"
          />
        </g>
        <path
          d="M62.0416 0.534674L57.4475 13.4657C56.5451 13.6249 55.6427 13.6249 54.7402 13.4657L52.1766 5.82645L49.6129 13.4657C48.7105 13.6249 47.8081 13.6249 46.9056 13.4657L42.3115 0.534674C43.4395 0.355629 44.5676 0.355629 45.6956 0.534674L48.2593 8.73096L50.8229 0.534674C51.7254 0.395418 52.6278 0.395418 53.5302 0.534674L56.0939 8.73096L58.6576 0.534674C59.7856 0.355629 60.9136 0.355629 62.0416 0.534674Z"
          fill="black"
        />
        <path
          d="M33.8512 0.236328C35.9226 0.236328 37.6249 0.892827 38.9375 2.20582C40.2501 3.51882 40.9269 5.11033 40.9269 7.00025C40.9269 8.91007 40.2501 10.5016 38.9375 11.8146C37.6249 13.1276 35.9226 13.7642 33.8512 13.7642C31.7592 13.7642 30.0569 13.1276 28.7443 11.8146C27.4317 10.5016 26.7754 8.91007 26.7754 7.00025C26.7754 5.11033 27.4317 3.51882 28.7443 2.20582C30.0569 0.892827 31.7592 0.236328 33.8512 0.236328ZM33.8512 2.88222C32.8052 2.88222 31.9233 3.2602 31.2054 4.01617C30.4671 4.77214 30.1184 5.76683 30.1184 7.00025C30.1184 8.25357 30.4671 9.24826 31.2054 10.0042C31.9233 10.7602 32.8052 11.1183 33.8512 11.1183C34.8766 11.1183 35.7585 10.7602 36.4969 10.0042C37.2147 9.24826 37.5839 8.25357 37.5839 7.00025C37.5839 5.76683 37.2147 4.77214 36.4969 4.01617C35.7585 3.2602 34.8766 2.88222 33.8512 2.88222Z"
          fill="black"
        />
        <path
          d="M24.4849 0.534674V13.4657C23.6235 13.6249 22.7621 13.6249 21.9212 13.4657L15.7068 5.88613V13.4657C14.5993 13.6646 13.5123 13.6646 12.4253 13.4657V0.534674C13.2867 0.395418 14.1276 0.395418 14.989 0.534674L21.2033 8.11425V0.534674C22.2903 0.355629 23.3773 0.355629 24.4849 0.534674Z"
          fill="black"
        />
        <path
          d="M5.47744 0.236328C6.76953 0.236328 8.0001 0.574525 9.16914 1.23102C9.25117 1.48964 9.29219 2.02678 9.3127 2.82253C9.3127 3.61829 9.27168 4.09574 9.16914 4.25489C7.5694 3.33978 6.33883 2.88222 5.49795 2.88222C5.02623 2.88222 4.65706 3.00158 4.39044 3.20052C4.1033 3.41935 3.98025 3.71776 3.98025 4.07585C3.98025 4.45383 4.14432 4.73235 4.47247 4.93129C4.80062 5.13023 5.41591 5.34906 6.35934 5.62757C6.85157 5.78673 7.28227 5.96577 7.65144 6.12492C8.02061 6.28407 8.41029 6.5228 8.82048 6.82121C9.23067 7.11962 9.55882 7.51749 9.78442 7.99495C10.01 8.4724 10.1331 9.02943 10.1331 9.66603C10.1331 10.979 9.66136 11.9936 8.73844 12.7098C7.81551 13.426 6.64647 13.7642 5.23132 13.7642C3.6726 13.7642 2.33949 13.4459 1.21147 12.7695C1.12943 12.5506 1.0679 12.0334 1.0679 11.2178C1.04739 10.4021 1.08841 9.90476 1.21147 9.74561C2.81121 10.6607 4.1033 11.1183 5.10827 11.1183C5.66202 11.1183 6.09272 11.0188 6.37985 10.8C6.66698 10.5812 6.81055 10.2827 6.81055 9.90476C6.81055 9.54667 6.64647 9.28805 6.33883 9.08911C6.01068 8.91007 5.35438 8.65145 4.34942 8.33314C3.85719 8.17399 3.447 8.01484 3.07783 7.8358C2.70866 7.67664 2.31898 7.43792 1.90879 7.11962C1.4986 6.82121 1.19096 6.44322 0.985866 5.96577C0.760262 5.50821 0.657715 4.99097 0.657715 4.39415C0.657715 3.22041 1.0679 2.22572 1.9293 1.42996C2.7907 0.634206 3.95974 0.236328 5.47744 0.236328Z"
          fill="black"
        />
      </svg>
    )
  }
}
