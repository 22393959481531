import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@repo/ui/components/form'
import { toast } from '@repo/ui/hooks/use-toast'
import { Input } from '@repo/ui/components/input'
import { useAuth } from '../../../../authentication'

const profileFormSchema = z.object({
  email: z.string().email(),
  firstName: z.string().min(2, {
    message: 'First name must be at least 2 characters.'
  }),
  surname: z
    .string()
    .min(2, {
      message: 'Surname name must be at least 2 characters.'
    })
    .optional()
})
type ProfileFormValues = z.infer<typeof profileFormSchema>

export function ProfileForm() {
  // External Hooks
  const auth = useAuth()

  // Local State
  const form = useForm<ProfileFormValues>({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      email: auth.user?.email,
      firstName: auth.user?.first_name,
      surname: auth.user?.surname
    },
    mode: 'onChange'
  })

  // Handlers
  function onSubmit(data: ProfileFormValues) {
    toast({
      title: 'You submitted the following values:',
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      )
    })
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="email" {...field} disabled={true} />
              </FormControl>
              <FormDescription>This is the email you use to login.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="firstName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <Input placeholder="first name" {...field} disabled={true} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="surname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Surname</FormLabel>
              <FormControl>
                <Input placeholder="surname" {...field} disabled={true} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* <Button type="submit">Update profile</Button> */}
      </form>
    </Form>
  )
}
