import Logo from '/favicon.svg'

export function PageNotFound() {
  return (
    <>
      <div>
        <a href="https://www.snowvault.com" target="_blank">
          <img src={Logo} className="logo" alt="Vite logo" />
        </a>
      </div>
      <h1>Page Not Found</h1>
    </>
  )
}
