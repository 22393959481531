// Example Snowvault server sent event:
// "{'some': 'data'}\n<END_OF_MESSAGE>\n{'some': 'more_data'}\n<END_OF_MESSAGE>\n"
// The EventTarget separates the above example into individual messages and dispatches them as MessageEvents containing the data as a string.
// Parsing the JSON response and type assertion is the responsibility of the consumer of the event stream.

// Separator is injected by the backend after every message because messages can be batched when received by the frontend.
const SseMessageSeparator = '\n<END_OF_MESSAGE>\n'
export const SseMessageTypeStart = 'START'
export const SseMessageTypeData = 'DATA'
export const SseMessageTypeClose = 'CLOSE'
export const SseMessageTypeAbort = 'ABORT'
export const SseMessageTypeError = 'ERROR'

export function FetchEventTarget(input: string, init: RequestInit) {
  const eventTarget = new EventTarget()
  const eventStream = makeWriteableEventStream(eventTarget)
  fetch(input, init)
    .then((response) => {
      response.body!.pipeThrough(new TextDecoderStream()).pipeTo(eventStream)
    })
    .catch((error) => {
      eventTarget.dispatchEvent(new CustomEvent(SseMessageTypeError, { detail: error }))
    })
  return eventTarget
}

function makeWriteableEventStream(eventTarget: EventTarget) {
  return new WritableStream({
    start() {
      eventTarget.dispatchEvent(new Event(SseMessageTypeStart))
    },
    write(message: string) {
      const messages = message.split(SseMessageSeparator)
      for (const message of messages) {
        const data = message.trim()
        if (data.length != 0) {
          eventTarget.dispatchEvent(new MessageEvent<string>(SseMessageTypeData, { data }))
        }
      }
    },
    close() {
      eventTarget.dispatchEvent(new CloseEvent(SseMessageTypeClose))
    },
    abort(reason) {
      eventTarget.dispatchEvent(new CloseEvent(SseMessageTypeAbort, { reason }))
    }
  })
}
