'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@repo/ui/components/form'
import { toast } from '@repo/ui/hooks/use-toast'
import { Input } from '@repo/ui/components/input'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { useAuth } from '../../../../authentication'

const organisationFormSchema = z.object({
  organisationName: z
    .string()
    .min(2, {
      message: 'Organisation name must be at least 2 characters.'
    })
    .max(30, {
      message: 'Organisation name must not be longer than 30 characters.'
    })
})

type OrganisationFormValues = z.infer<typeof organisationFormSchema>

export function OrganisationForm() {
  const { user } = useAuth()

  const form = useForm<OrganisationFormValues>({
    resolver: zodResolver(organisationFormSchema),
    defaultValues: {
      organisationName: user?.org.name
    },
    mode: 'onChange'
  })

  function onSubmit(data: OrganisationFormValues) {
    toast({
      title: 'You submitted the following values:',
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      )
    })
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="organisationName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Organisation Name</FormLabel>
              <FormControl>
                <Input placeholder="Your organisation's name" {...field} disabled={true} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* <Button type="submit">Update organisation</Button> */}
      </form>
    </Form>
  )
}
