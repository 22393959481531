import { Footer } from '@repo/ui/components/footer'
import { Logo } from '@repo/ui/components/logo'
import { NavBar } from '@repo/ui/components/navBar'
import { NavLink, Outlet } from 'react-router'

export const DefaultLayout = () => {
  return (
    <div className={`align-center dark flex min-h-screen flex-col items-center antialiased bg-[#0f070f]`}>
      <NavBar className="max-w-page">
        <NavLink to="/app">
          <Logo variant="LIGHT" testId="nav-snowvault-logo" />
        </NavLink>
      </NavBar>
      <Outlet />
      <Footer logoProps={{ variant: 'LIGHT' }} />
    </div>
  )
}
//

export default DefaultLayout
