import { FetchEventTarget, SseMessageTypeData } from '@/src/common/ssEventStream'
import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from '@repo/ui/components/breadcrumb'
import { Button } from '@repo/ui/components/button'
import { Input } from '@repo/ui/components/input'
import { Label } from '@repo/ui/components/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@repo/ui/components/select'
import { Separator } from '@repo/ui/components/separator'
import { SidebarTrigger } from '@repo/ui/components/sidebar'
import { Textarea } from '@repo/ui/components/textarea'
import { Typography } from '@repo/ui/components/typography'
import { useQuery } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { z, ZodError } from 'zod'
import { apiErrorHandler } from '../../api/errorHandler'
import { useAuth } from '../../authentication'
import { parseDataOfType } from '../../common/parseDataType'
import { contentTabKey, contentTabQAPair } from '../content-management/contentManagement'

const minModelTemp = 0.0000000001
const ragEmailAgentQuerySchema = z
  .object({
    emailQuery: z.string().min(1, 'Email cannot be empty'),
    llmModel: z.string().optional(),
    modelTemp: z
      .number()
      .min(minModelTemp, `Min temperature is ${minModelTemp}`)
      .max(1, 'Max temperature is 1')
      .optional(),
    promptVersion: z.number().min(1, 'Min prompt version is 1').optional()
  })
  .describe('RagEmailAgentQuery')
type RagEmailAgentQuery = z.infer<typeof ragEmailAgentQuerySchema>

const ragEmailAgentResponseSchema = z
  .object({
    emailResponse: z.string()
  })
  .describe('RagEmailAgentResponse')
// type RagEmailAgentResponse = z.infer<typeof ragEmailAgentResponseSchema>

const emailAgentModelsSchema = z
  .object({
    defaultModel: z.string(),
    defaultPromptVersion: z.number(),
    models: z.array(
      z.object({
        model: z.string(),
        defaultTemperature: z.number()
      })
    )
  })
  .describe('EmailAgentModelConfigs')
type EmailAgentModelConfigs = z.infer<typeof emailAgentModelsSchema>

async function getEmailAgentModelConfigs(): Promise<EmailAgentModelConfigs> {
  const res = await fetch(`${window.location.origin}/api/rag/emailAgent/dev/modelConfigs`, {
    credentials: 'same-origin'
  })
  await apiErrorHandler(res)

  const data = await res.json()
  return parseDataOfType(data, emailAgentModelsSchema)
}

const createEmailQNASchema = z.object({
  emailQuery: z.string().min(1, 'Email cannot be empty'),
  emailResponse: z.string().min(1, 'Email response cannot be empty')
})

const createEmailQNAResponseSchema = z
  .object({
    id: z.number()
  })
  .describe('CreateEmailQNAResponse')

async function createEmailQNA(saveEmailQNAData: z.infer<typeof createEmailQNASchema>) {
  try {
    const data = createEmailQNASchema.parse(saveEmailQNAData)
    const res = await fetch(`${window.location.origin}/api/rag/qna`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        question: encodeURIComponent(data.emailQuery),
        answer: encodeURIComponent(data.emailResponse)
      })
    })
    await apiErrorHandler(res)

    return parseDataOfType(await res.json(), createEmailQNAResponseSchema)
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(`Failed to create email QNA: ${err.message}`)
    }
    throw err
  }
}

export function EmailAgentPage() {
  const { user } = useAuth()

  const { data, isError, isLoading, error } = useQuery({
    queryKey: ['emailAgentModelConfigs'],
    queryFn: () => getEmailAgentModelConfigs(),
    enabled: user?.enabled_features.email_agent_dev ?? false
  })

  if (isLoading) {
    return <Loader2 className="m-auto" />
  }

  if (isError) {
    console.log('Error getting EmailAgentModelConfigs:', error)
  }

  return (
    <>
      <header className="flex h-14 shrink-0 items-center gap-2">
        <div className="flex flex-1 items-center gap-2 ">
          <SidebarTrigger />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage className="line-clamp-1">Email Agent</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <EmailAgentForm emailAgentModelConfigs={isError ? undefined : data} />
    </>
  )
}

interface EmailAgentFormProps {
  emailAgentModelConfigs: EmailAgentModelConfigs | undefined
}

function EmailAgentForm(props: EmailAgentFormProps) {
  const [submitting, setSubmitting] = useState(false)
  const [emailQuery, setEmailQuery] = useState('')
  const [llmModel, setLlmModel] = useState(props.emailAgentModelConfigs?.defaultModel ?? '')
  const [modelTemp, setModelTemp] = useState(
    () =>
      props.emailAgentModelConfigs?.models.find((m) => m.model === props.emailAgentModelConfigs?.defaultModel)
        ?.defaultTemperature ?? 0.5
  )
  const [promptVersion, setPromptVersion] = useState(props.emailAgentModelConfigs?.defaultPromptVersion ?? 1)
  const [emailResponse, setEmailResponse] = useState('')
  const [savingAndCopying, setSavingAndCopying] = useState(false)
  const [emailSaved, setEmailSaved] = useState(false)
  const [error, setError] = useState('')
  const auth = useAuth()
  const navigate = useNavigate()

  const onSubmit = async () => {
    setError('')
    setSubmitting(true)
    setEmailResponse('')
    setEmailSaved(false)
    try {
      const query: RagEmailAgentQuery = { emailQuery }
      const devEnabled = auth.user?.enabled_features.email_agent_dev ?? false
      if (devEnabled) {
        query.llmModel = llmModel
        query.modelTemp = modelTemp
        query.promptVersion = promptVersion
      }
      const emailAgentQuery = ragEmailAgentQuerySchema.parse(query)

      let url = `${window.location.origin}/api/rag/emailAgent`
      if (devEnabled) {
        url += '/dev'
      }

      // const abortController = new AbortController()

      const eventTarget = FetchEventTarget(url, {
        method: 'POST',
        headers: new Headers({
          'content-type': 'application/json'
        }),
        mode: 'same-origin',
        // signal: abortController.signal,
        body: JSON.stringify(emailAgentQuery)
      })
      eventTarget.addEventListener(SseMessageTypeData, (event) => {
        const msg = event as MessageEvent<string>
        const data = parseDataOfType(JSON.parse(msg.data), ragEmailAgentResponseSchema)
        setEmailResponse((prev) => prev + data.emailResponse)
        const el = document.getElementById('emailResponse')
        if (el) {
          el.style.height = 'auto'
          el.style.height = el.scrollHeight + 5 + 'px'
        }
      })
      eventTarget.addEventListener('error', (event) => {
        throw new Error((event as CustomEvent).detail)
      })
    } catch (err) {
      if (err instanceof ZodError) {
        setError(err.errors.map((e) => e.message).join('\n'))
        return
      }
      if (err instanceof Error) {
        setError(err.message)
        return
      }
      throw err
    } finally {
      setSubmitting(false)
    }
  }

  const onSaveAndCopy = async () => {
    setSavingAndCopying(true)

    try {
      setError('')
      await createEmailQNA({ emailQuery, emailResponse })
      await navigator.clipboard.writeText(emailResponse)
      setEmailSaved(true)
      setEmailQuery('')
      setEmailResponse('')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
        return
      }
      throw err
    } finally {
      setSavingAndCopying(false)
    }
  }

  return (
    <div className="space-y-4 py-2 px-4">
      <Textarea
        id="emailQuery"
        rows={10}
        placeholder="Paste the received email here..."
        value={emailQuery}
        onChange={(e) => {
          setEmailQuery(e.target.value)
          const el = document.getElementById('emailQuery')
          if (el) {
            el.style.height = 'auto'
            el.style.height = el.scrollHeight + 5 + 'px'
          }
        }}
        disabled={submitting}
      ></Textarea>
      <div className="flex">
        <Button
          variant="brand"
          size="default"
          // align="end"
          onClick={onSubmit}
          disabled={submitting}
        >
          {submitting && <Loader2 className="mr-2 animate-spin" />}Generate Email Response
        </Button>
        {auth.user?.enabled_features.email_agent_dev && (
          <div className="flex">
            <Label className="p-3">LLM Model</Label>
            <Select
              value={llmModel}
              onValueChange={(value) => {
                setLlmModel(value)
                setModelTemp(
                  props.emailAgentModelConfigs?.models.find((m) => m.model === value)?.defaultTemperature ?? 0.5
                )
              }}
              disabled={submitting}
            >
              <SelectTrigger className="w-auto">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>LLM Model</SelectLabel>
                  {props.emailAgentModelConfigs?.models.map(({ model }) => (
                    <SelectItem key={model} value={model}>
                      {model}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Label className="p-3 min-w-[7rem]">Model Temp</Label>
            <Input
              className="w-auto min-w-[10rem] mt-0.5"
              type="number"
              min={0}
              max={1}
              step={0.0000001}
              id="modelTemp"
              placeholder=">0 to 1"
              value={modelTemp}
              onChange={(e) => setModelTemp(Number(e.target.value))}
              disabled={submitting}
            ></Input>
            <Label className="p-3 min-w-[7rem]">Prompt Version</Label>
            <Input
              className="w-20 mt-0.5"
              type="number"
              min={1}
              step={1}
              id="promptVersion"
              placeholder="1 or greater"
              value={promptVersion}
              onChange={(e) => setPromptVersion(Number(e.target.value))}
              disabled={submitting}
            ></Input>
          </div>
        )}
      </div>
      {error && <Typography className="text-destructive">{error}</Typography>}
      <Textarea
        id="emailResponse"
        rows={10}
        placeholder=""
        value={emailResponse}
        onChange={(e) => setEmailResponse(e.target.value)}
        disabled={savingAndCopying}
      ></Textarea>
      <Button
        variant="brand"
        size="default"
        onClick={onSaveAndCopy}
        disabled={savingAndCopying || emailResponse === ''}
      >
        {savingAndCopying && <Loader2 className="mr-2 animate-spin" />}Save & Copy to Clipboard
      </Button>
      {emailSaved && (
        <Button
          variant="link"
          size="default"
          onClick={() => navigate(`/app/content-management?${contentTabKey}=${contentTabQAPair}`)}
        >
          Email saved to Content Management
        </Button>
      )}
    </div>
  )
}
