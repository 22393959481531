import { Avatar, AvatarFallback } from '@repo/ui/components/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@repo/ui/components/dropdown-menu'
import {
  ContentManagementIcon,
  DashboardIcon,
  EmailAgentIcon,
  ScriptIcon,
  SnowvaultIcon
} from '@repo/ui/components/icons'
import { Logo } from '@repo/ui/components/logo'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar
} from '@repo/ui/components/sidebar'
import { cn } from '@repo/ui/lib/utils'
import { ChevronsUpDown, LogOut, Settings } from 'lucide-react'
import { useMemo } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router'
import { useAuth } from '../authentication'

export function AuthenticatedLayout() {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { user, logout } = useAuth()

  const { isMobile, state, setOpenMobile } = useSidebar()

  const sidebarItems = useMemo(
    () => [
      {
        title: 'Overview',
        url: '/app',
        icon: DashboardIcon,
        enabled: true
      },
      {
        title: 'Content Management',
        url: '/app/content-management',
        icon: ContentManagementIcon,
        enabled: user?.enabled_features.search_agent ?? false
      },
      {
        title: 'Email Agent',
        url: '/app/email-agent',
        icon: EmailAgentIcon,
        enabled: user?.enabled_features.email_agent ?? false
      },
      {
        title: 'Email Agent Concept',
        url: '/app/email-agent-concept',
        icon: EmailAgentIcon,
        enabled: user?.enabled_features.email_agent_dev ?? false
      },
      {
        title: 'Script Agent',
        url: '/app/script-agent',
        icon: ScriptIcon,
        enabled: user?.enabled_features.script_writer_agent ?? false
      },
      {
        title: 'Storyboard Agent',
        url: '/app/storyboard-agent-demo',
        icon: ScriptIcon,
        enabled: user?.enabled_features.story_board_agent ?? false
      }
    ],
    [user?.enabled_features]
  )

  return (
    <>
      <Sidebar collapsible="icon" variant="inset">
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton size="lg" className={cn(state === 'collapsed' && 'flex items-center justify-center')}>
                <Link to="/app">
                  {isMobile || state === 'expanded' ? <Logo variant="DARK" /> : <SnowvaultIcon className="size-5" />}
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
          <SidebarGroup>
            <SidebarGroupContent>
              <SidebarMenu>
                {state === 'expanded' && (
                  <SidebarMenuItem>
                    <SidebarMenuButton asChild className="hover:bg-sidebar active:bg-sidebar">
                      <span className="h-8 font-semibold p-2">{user?.org.name}</span>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                )}
                {sidebarItems
                  .filter((item) => item.enabled)
                  .map((item) => (
                    <SidebarMenuItem key={item.title}>
                      <SidebarMenuButton asChild tooltip={item.title} isActive={pathname === item.url}>
                        <Link to={item.url} onClick={() => setOpenMobile(false)}>
                          <item.icon className="fill-black" />
                          <span>{item.title}</span>
                        </Link>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>
        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton
                    size="lg"
                    className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                  >
                    <Avatar className="h-8 w-8 rounded-lg">
                      <AvatarFallback className="rounded-lg">
                        {user?.first_name[0]}
                        {user?.surname[0]}
                      </AvatarFallback>
                    </Avatar>
                    <div className="grid flex-1 text-left text-sm leading-tight">
                      <span className="truncate font-semibold">
                        {user?.first_name} {user?.surname}
                      </span>
                      <span className="truncate text-xs">{user?.email}</span>
                    </div>
                    <ChevronsUpDown className="ml-auto size-4" />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                  side={isMobile ? 'bottom' : 'right'}
                  align="end"
                  sideOffset={4}
                >
                  <DropdownMenuLabel className="p-0 font-normal">
                    <div className="flex items-center gap-2 px-1 py-1.5 text-left text-sm">
                      <Avatar className="h-8 w-8 rounded-lg">
                        <AvatarFallback className="rounded-lg">
                          {user?.first_name[0]}
                          {user?.surname[0]}
                        </AvatarFallback>
                      </Avatar>
                      <div className="grid flex-1 text-left text-sm leading-tight">
                        <span className="truncate font-semibold">
                          {user?.first_name} {user?.surname}
                        </span>
                        <span className="truncate text-xs">{user?.email}</span>
                      </div>
                    </div>
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem asChild>
                      <Link to="/app/settings/profile">
                        <Settings />
                        Settings
                      </Link>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuItem onClick={() => logout(() => navigate('/app/login'))}>
                    <LogOut />
                    Log out
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
      </Sidebar>

      <SidebarInset className="px-4 w-full pb-4">
        <Outlet />
      </SidebarInset>
    </>
  )
}
