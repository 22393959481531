import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { EmailList } from './_components/emailList/emailList'
import { EmailPreview } from './_components/emailPreview/emailPreview'
import { Breadcrumb, BreadcrumbList, BreadcrumbItem, BreadcrumbPage } from '@repo/ui/components/breadcrumb'
import { Separator } from '@repo/ui/components/separator'
import { SidebarTrigger } from '@repo/ui/components/sidebar'

export type EmailFrom = {
  name: string
  email: string
  subject: string
  dateReceived: string
}

export type EmailData = {
  id: number
  from: EmailFrom
} & (
  | {
      state: 'DRAFT'
      response: {
        subject: string
        bodyContent: string
      }
    }
  | {
      state: 'UNREAD'
    }
  | {
      state: 'REPLIED'
      response: {
        subject: string
        dateSent: string
        bodyContent: string
      }
    }
)

// External APIs
async function fetchEmails() {
  return new Promise<EmailData[]>((resolve) => {
    const emails: EmailData[] = [
      {
        id: 1,
        state: 'DRAFT',
        from: {
          name: 'John Doe',
          email: 'john.doe@overseas.com',
          dateReceived: 'Today, 10:00 AM',
          subject: 'Some enquiry from a customer'
        },
        response: {
          subject: 'Re: Some enquiry from a customer',
          bodyContent:
            'Dear John,\n\nThank you for your interest in Milner College.Please find attached price information for students from Brazil.\n\nFeel free to contact me if you have any queries.\n\nKind regards,\n\nCarla Zafalon\nStudent Services'
        }
      },
      {
        id: 2,
        state: 'REPLIED',
        from: {
          name: 'Lucas Silva',
          email: 'lucas.silva@overseas.com',
          dateReceived: 'Yesterday, 2:00 PM',
          subject: 'Some enquiry from a customer'
        },
        response: {
          subject: 'Re: Some enquiry from a customer',
          dateSent: 'Today, 09:00 AM',
          bodyContent:
            'Dear Lucas,\n\nThank you for your interest in Milner College.Please find attached price information for students from Brazil.\n\nFeel free to contact me if you have any queries.\n\nKind regards,\n\nCarla Zafalon\nStudent Services'
        }
      },
      {
        id: 3,
        state: 'UNREAD',
        from: {
          name: 'Loraine Phre',
          email: 'loraine.phre@overseas.com',
          dateReceived: 'Last week, 10:00 AM',
          subject: 'Some enquiry from a customer'
        }
      }
    ]
    setTimeout(() => {
      const sortedEmails = emails.sort((a, b) => {
        if (a.state === 'UNREAD' && b.state === 'REPLIED') {
          return -1
        }
        if (a.state === 'REPLIED' && b.state === 'UNREAD') {
          return 1
        }
        return 0
      })
      resolve(sortedEmails)
    }, 2300)
  })
}

export function EmailAgentConceptPage() {
  // Queries
  const { data, isLoading } = useQuery<unknown, Error, EmailData[]>({
    queryKey: ['getEmails'],
    queryFn: () => fetchEmails()
  })

  // Local state
  const [active, setActive] = useState(0)

  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      setActive(data[0].id)
    }
  }, [data])

  // Mutators
  const previewEmailData = Array.isArray(data) ? data.find((email) => email.id === active) : undefined

  return (
    <>
      <header className="flex h-14 shrink-0 items-center gap-2">
        <div className="flex flex-1 items-center gap-2 ">
          <SidebarTrigger />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage className="line-clamp-1">Email Agent Concept</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <div data-testid="email-list" className="flex w-full flex-row space-x-4 py-2 px-4">
        <EmailList emailData={data ?? []} isLoading={isLoading} setActive={setActive} active={active} />
        <EmailPreview previewEmailData={previewEmailData} isLoading={isLoading} />
      </div>
    </>
  )
}
