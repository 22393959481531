import { z } from 'zod'

/**
 * Parse data using Zod type guard
 */
export function parseDataOfType<T extends z.ZodTypeAny>(data: unknown, schema: T) {
  const parsed = schema.safeParse(data)
  const errorMessage = `Failed to parse data for type: '${
    schema.description ?? 'TypeName missing, add description to Zod schema'
  }'`
  if (!parsed.success) {
    console.log(`${errorMessage}, got: ${JSON.stringify(data, null, 2)} \nerror: ${parsed.error.message}`)
    throw new Error(errorMessage)
  }
  return parsed.data as z.infer<T>
}

/**
 * Validate data type using Zod
 */
export function isDataOfType<T extends z.ZodTypeAny>(data: unknown, schema: T): data is z.infer<T> {
  return schema.safeParse(data).success
}
