import { NavigationMenu, NavigationMenuItem, NavigationMenuList } from '@repo/ui/components/navigation-menu'
import { PropsWithChildren } from 'react'
import { cn } from '../lib/utils'
import { Logo, LogoProps } from './logo'

export function Footer(props: PropsWithChildren<{ className?: string; logoProps: LogoProps }>) {
  return (
    <NavigationMenu
      className={cn('max-w-page sticky top-[100vh] flex max-h-12 w-full flex-row justify-between p-4', props.className)}
    >
      <div>
        <a href="/">
          <Logo {...props.logoProps} testId="footer-snowvault-logo" />
        </a>
      </div>
      <NavigationMenuList className="text-xs">
        {props.children}
        <a href="/privacy-policy">
          <NavigationMenuItem>Privacy Policy</NavigationMenuItem>
        </a>
        {/* <NavigationMenuItem>Security Policy</NavigationMenuItem> */}
      </NavigationMenuList>
    </NavigationMenu>
  )
}
