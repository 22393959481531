import Markdown, { Components } from 'react-markdown'

const components: Components = {
  h1: ({ children }: { children?: React.ReactNode }) => <h1 className="text-2xl mb-4 font-bold">{children}</h1>,
  h2: ({ children }: { children?: React.ReactNode }) => <h2 className="text-xl mb-3 font-bold">{children}</h2>,
  h3: ({ children }: { children?: React.ReactNode }) => <h3 className="text-lg mb-2 font-bold">{children}</h3>,
  h4: ({ children }: { children?: React.ReactNode }) => <h4 className="text-lg mb-2 font-bold">{children}</h4>,
  blockquote: ({ children }: { children?: React.ReactNode }) => (
    <blockquote className="border-l-4 pl-4 mb-4 italic">{children}</blockquote>
  ),
  pre: ({ children }: { children?: React.ReactNode }) => (
    <pre className="ml-[1em] mb-[1em] bg-[#f5f4f7] p-[0.8em] rounded-[0.3em] inline-block">{children}</pre>
  ),
  b: ({ children }: { children?: React.ReactNode }) => <b className="font-bold">{children}</b>,
  p: ({ children }: { children?: React.ReactNode }) => <p className="mb-4">{children}</p>,
  ul: ({ children }: { children?: React.ReactNode }) => <ul className="list-disc ml-6 mb-4">{children}</ul>,
  ol: ({ children }: { children?: React.ReactNode }) => <ol className="list-decimal ml-6 mb-4">{children}</ol>,
  li: ({ children }: { children?: React.ReactNode }) => <li className="mb-2">{children}</li>,
  a: ({ children, href }: { children?: React.ReactNode; href?: string }) => (
    <a className="text-blue-600 hover:underline" href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

export default function MarkdownRenderer({ children }: { children: string }) {
  return <Markdown components={components}>{children}</Markdown>
}
