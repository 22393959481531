import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@repo/ui/components/alert-dialog'
import { Button } from '@repo/ui/components/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@repo/ui/components/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@repo/ui/components/dropdown-menu'
import { UploadPdfIcon } from '@repo/ui/components/icons'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@repo/ui/components/table'
import { Textarea } from '@repo/ui/components/textarea'
import { Typography } from '@repo/ui/components/typography'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Ellipsis, Pencil, Trash } from 'lucide-react'
import { ComponentProps, useState } from 'react'
import { z } from 'zod'
import { apiErrorHandler } from '../../../api/errorHandler'
import { parseDataOfType } from '../../../common/parseDataType'

export const getRagFilesQueryKey = 'getRagFiles'

const ragFileSchema = z
  .object({
    docID: z.number(),
    // TODO: What should this be?  File name?
    description: z.string(),
    fileName: z.string(),
    fileSizeBytes: z.number(),
    uploadedOnTsMs: z.number(),
    uploadedByName: z.string()
  })
  .describe('RagFile')
type RagFile = z.infer<typeof ragFileSchema>

async function getRagFiles() {
  const res = await fetch(`${window.location.origin}/api/rag/file`, {
    method: 'GET',
    credentials: 'same-origin'
  })
  await apiErrorHandler(res)

  const data = await res.json()
  return parseDataOfType(data, z.array(ragFileSchema).describe('RagFiles'))
}

type RagFileUpdate = {
  description: string
}

async function updateRagFile(id: string, fileUpdate: RagFileUpdate) {
  const res = await fetch(`${window.location.origin}/api/rag/file/${id}`, {
    method: 'PUT',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(fileUpdate)
  })
  await apiErrorHandler(res)
}

async function deleteRagFile(id: string) {
  const res = await fetch(`${window.location.origin}/api/rag/file/${id}`, {
    method: 'DELETE',
    credentials: 'same-origin'
  })
  await apiErrorHandler(res)
}

export function StoredRagFiles() {
  const getRagFilesQueryResult = useQuery<unknown, Error, RagFile[]>({
    queryKey: [getRagFilesQueryKey],
    queryFn: () => getRagFiles()
  })

  return (
    <div className="w-full animate-appear space-y-8 opacity-0">
      <Typography variant="primary" size="h2" className="flex flex-row items-center space-x-2">
        <div>
          <UploadPdfIcon className="fill-primary" />
        </div>
        <div>Files</div>
      </Typography>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[24em]">File Name</TableHead>
            <TableHead className="w-full">Description</TableHead>
            <TableHead className="min-w-[8em]">Size MB</TableHead>
            <TableHead className="min-w-[12em]">Uploaded On</TableHead>
            <TableHead className="min-w-[12em]">Uploaded By</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {/* TODO: Change this to isLoading Skeleton */}
          {getRagFilesQueryResult.isLoading ? (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          ) : (
            (getRagFilesQueryResult.data ?? []).map((item) => (
              <TableRow key={item.docID}>
                <TableCell>{item.fileName}</TableCell>
                <TableCell className="whitespace-pre-wrap">{item.description}</TableCell>
                <TableCell>{String((item.fileSizeBytes / 1000 / 1000).toFixed(1))}</TableCell>
                <TableCell>{new Date(item.uploadedOnTsMs).toLocaleString()}</TableCell>
                <TableCell>{item.uploadedByName}</TableCell>
                <TableCell className="align-top">
                  <Options item={item} />
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </div>
  )
}

const Options: React.FC<{ item: RagFile }> = ({ item }) => {
  const [showEdit, setShowEdit] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  return (
    <>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger>
          <Ellipsis />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => setShowEdit(true)}>
            <Pencil />
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setShowDelete(true)}>
            <Trash />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <EditDialog open={showEdit} onOpenChange={setShowEdit} ragFile={item} />

      <DeleteDialog open={showDelete} onOpenChange={setShowDelete} ragFile={item} />
    </>
  )
}

const EditDialog: React.FC<ComponentProps<typeof AlertDialog> & { ragFile: RagFile }> = ({ ragFile, ...props }) => {
  const queryClient = useQueryClient()
  const [description, setDescription] = useState(ragFile.description)
  const [error, setError] = useState('')

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async () => {
    if (description.length == 0) {
      setError('Description cannot be empty')
      return
    }

    try {
      setSubmitting(true)
      await updateRagFile(ragFile.docID.toString(), {
        // Text is encoded to preserve formatting and special characters.
        description: encodeURIComponent(description)
      })
      props.onOpenChange?.(false)
    } catch (e) {
      if (e instanceof Error) {
        console.log(e)
        setError(e.message)
      }
    } finally {
      setSubmitting(false)
      queryClient.invalidateQueries({ queryKey: [getRagFilesQueryKey], exact: false })
    }
  }

  return (
    <AlertDialog {...props}>
      <AlertDialogContent className="max-w-4xl">
        <AlertDialogHeader>
          <AlertDialogTitle>Edit Description</AlertDialogTitle>
          <AlertDialogDescription className="sr-only">Edit Description Modal</AlertDialogDescription>
        </AlertDialogHeader>
        <Textarea
          className="min-h-[200px] my-4"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
        />
        {error && <p className="mt-[0.35rem] text-destructive">{error}</p>}
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button disabled={submitting} onClick={onSubmit}>
            Save
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const DeleteDialog: React.FC<ComponentProps<typeof Dialog> & { ragFile: RagFile }> = ({ ragFile, ...props }) => {
  const queryClient = useQueryClient()
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async () => {
    try {
      setSubmitting(true)
      await deleteRagFile(ragFile.docID.toString())
      setError('')
      props.onOpenChange?.(false)
    } catch (e) {
      if (e instanceof Error) {
        console.log(e)
        setError(e.message)
      }
    } finally {
      setSubmitting(false)
      queryClient.invalidateQueries({ queryKey: [getRagFilesQueryKey], exact: false })
    }
  }

  return (
    <Dialog {...props}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete File</DialogTitle>
          <DialogDescription className="sr-only">Delete File Modal</DialogDescription>
        </DialogHeader>
        <p>Are you sure you want to delete this file? This action cannot be undone.</p>
        {error && <p className="mt-[0.35rem] text-destructive">{error}</p>}
        <DialogFooter>
          <Button variant="outline" onClick={() => props.onOpenChange?.(false)}>
            Cancel
          </Button>
          <Button variant="destructive" disabled={submitting} onClick={onSubmit}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
