import { buttonVariants } from '@repo/ui/components/button'
import { NavLink } from 'react-router'
import { cn } from '../../../../lib/utils'

interface SidebarNavProps extends React.HTMLAttributes<HTMLElement> {
  items: {
    href: string
    title: string
  }[]
}

export function SidebarNav({ className, items, ...props }: SidebarNavProps) {
  return (
    <nav className={cn('flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1', className)} {...props}>
      {items.map((item) => (
        <NavLink
          key={item.href}
          to={item.href}
          className={({ isActive }) =>
            cn(
              buttonVariants({ variant: 'ghost', size: 'default' }),
              isActive ? 'bg-muted hover:bg-muted' : 'hover:bg-transparent hover:underline',
              'justify-start text-[16px]'
            )
          }
        >
          {item.title}
        </NavLink>
      ))}
    </nav>
  )
}
