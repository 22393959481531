import { Breadcrumb, BreadcrumbItem, BreadcrumbList, BreadcrumbPage } from '@repo/ui/components/breadcrumb'
import { QuestionAnswerPairIcon, UploadFileIcon, UploadPdfIcon } from '@repo/ui/components/icons'
import { Separator } from '@repo/ui/components/separator'
import { SidebarTrigger } from '@repo/ui/components/sidebar'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@repo/ui/components/tabs'
import { Typography } from '@repo/ui/components/typography'
import { useState } from 'react'
import { useSearchParams } from 'react-router'
import { useAuth } from '../../authentication'
import { StoredRagFiles } from './_components/storedFiles'
import { StoredQAPairs } from './_components/storedQAPairs'
import { StoredRagTexts } from './_components/storedText'
import { UploadRagFile } from './_components/uploadFile'
import { UploadQAPair } from './_components/uploadQAPair'
import { UploadRagText } from './_components/uploadText'

export const contentTabKey = 'tab'
const contentTabText = 'text'
export const contentTabQAPair = 'qa_pair'
const contentTabFile = 'file'
const allTabs = [contentTabText, contentTabQAPair]

export function ContentManagementPage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [tab, setTab] = useState(contentTabText)

  if (searchParams.has(contentTabKey)) {
    const tabInParams = searchParams.get(contentTabKey) || ''
    const tabToSelect = allTabs.includes(tabInParams) ? tabInParams : contentTabText
    setTab(tabToSelect)
    searchParams.delete(contentTabKey)
    setSearchParams(searchParams)
  }

  const onTabChange = (value: string) => {
    setTab(value)
  }

  const auth = useAuth()
  return (
    <>
      <header className="flex h-14 shrink-0 items-center gap-2">
        <div className="flex flex-1 items-center gap-2 ">
          <SidebarTrigger />
          <Separator orientation="vertical" className="mr-2 h-4" />
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage className="line-clamp-1">Content Management</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      </header>
      <Tabs value={tab} onValueChange={onTabChange} className="w-full space-y-8 px-4 py-2">
        <div className="flex animate-appear flex-col justify-start gap-4 rounded-sm border-[1px] border-border bg-card-background px-5 py-[30px] opacity-0 shadow-md">
          <Typography variant="primary" size="h2" margin="off">
            Upload New Content
          </Typography>
          <TabsList className="justify-start gap-3 p-0 pb-4">
            <TabsTrigger value={contentTabText}>
              <UploadPdfIcon className="-ml-2 mr-2 fill-primary" />
              Text
            </TabsTrigger>
            {auth.user?.enabled_features.email_agent && (
              <TabsTrigger value={contentTabQAPair}>
                <QuestionAnswerPairIcon className="-ml-2 mr-2 fill-primary" />
                Q&A Pairs for Email Agent
              </TabsTrigger>
            )}
            {auth.user?.enabled_features.email_agent_dev && (
              <TabsTrigger value={contentTabFile}>
                <UploadFileIcon className="-ml-2 mr-2" />
                File
              </TabsTrigger>
            )}
          </TabsList>
          <TabsContent value={contentTabText} className="mt-0">
            <UploadRagText />
          </TabsContent>
          {auth.user?.enabled_features.email_agent && (
            <TabsContent value={contentTabQAPair} className="mt-0">
              <UploadQAPair />
            </TabsContent>
          )}
          {auth.user?.enabled_features.email_agent_dev && (
            <TabsContent value={contentTabFile} className="mt-0">
              <UploadRagFile />
            </TabsContent>
          )}
        </div>

        <TabsContent value={contentTabText}>
          <StoredRagTexts />
        </TabsContent>
        {auth.user?.enabled_features.email_agent && (
          <TabsContent value={contentTabQAPair}>
            <StoredQAPairs />
          </TabsContent>
        )}
        {auth.user?.enabled_features.email_agent_dev && (
          <TabsContent value={contentTabFile}>
            <StoredRagFiles />
          </TabsContent>
        )}
      </Tabs>
    </>
  )
}
