import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@repo/ui/components/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@repo/ui/components/form'
import { Input } from '@repo/ui/components/input'
import { Typography } from '@repo/ui/components/typography'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'
import { z } from 'zod'
import { useAuth, UserPwSchema } from '../../authentication'

const userPasswordSchema = z.object({
  password1: UserPwSchema,
  password2: UserPwSchema
})
type UserPasswordType = z.infer<typeof userPasswordSchema>

export function UserVerify() {
  // hooks
  const searchParams = new URLSearchParams(useLocation().search)
  const [passwordSet, setPasswordSet] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const auth = useAuth()
  const navigate = useNavigate()

  const form = useForm<UserPasswordType>({
    resolver: zodResolver(userPasswordSchema),
    defaultValues: {
      password1: '',
      password2: ''
    }
  })

  async function onSubmit(data: UserPasswordType) {
    try {
      setLoading(true)
      setError('')

      const email = searchParams.get('email')
      const token = searchParams.get('token')
      if (!email || !token) {
        throw new Error('Invalid email or token in URL')
      }

      await auth.verifyUser(email, token, data.password1, data.password2)
      setPasswordSet(true)

      await auth.login(email, data.password1, () => {})

      setTimeout(() => {
        navigate('/app')
      }, 5000)
    } catch (e) {
      console.error(e)
      if (e instanceof Error) {
        setError(e.message)
      }
    } finally {
      form.reset()
      setLoading(false)
    }
  }

  return (
    <div className="flex h-[400px] min-h-[760px] w-full bg-[url('/app/images/hero_image.png')] bg-cover bg-center">
      <div className="m-auto flex flex-col items-center justify-center space-y-4">
        <Typography size={'h1'} slot="h1" className="text-5xl">
          Set your password
        </Typography>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-[30em] max-w-[90vw] animate-appear space-y-6 rounded-sm bg-white p-9 py-9 opacity-0"
          >
            {error && <p className="mt-2 text-destructive">{error}</p>}
            {passwordSet && (
              <p className="mt-2 text-brand">
                Your password has successfully been set
                <br />
                Logging-in in 5 seconds...
              </p>
            )}
            <FormField
              control={form.control}
              name="password1"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input type="password" {...field} disabled={loading || passwordSet} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password2"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Re-enter your password</FormLabel>
                  <FormControl>
                    <Input type="password" {...field} disabled={loading || passwordSet} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="mt-4 w-full" disabled={loading || passwordSet}>
              Submit
            </Button>
          </form>
        </Form>
      </div>
    </div>
  )
}
