import { Separator } from '@repo/ui/components/separator'
import { TagForm } from './tag-form'
import { TagList } from './tagList'

export default function SettingsTagsPage() {
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Tags</h3>
      </div>
      <Separator />
      <TagForm />
      <TagList />
    </div>
  )
}
