import { Button } from '@repo/ui/components/button'
import { Textarea } from '@repo/ui/components/textarea'
import { Typography } from '@repo/ui/components/typography'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { apiErrorHandler } from '../../../api/errorHandler'
import { getQAPairsQueryKey } from './storedQAPairs'

type RagAddQAPair = {
  question: string
  answer: string
}

async function uploadQAPair(ragAddQAPair: RagAddQAPair) {
  const res = await fetch(`${window.location.origin}/api/rag/qna`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify(ragAddQAPair)
  })
  await apiErrorHandler(res)
}

export function UploadQAPair() {
  const queryClient = useQueryClient()
  const [error, setError] = useState('')

  const [submitting, setSubmitting] = useState(false)
  const [newQuestion, setNewQuestion] = useState('')
  const [newAnswer, setNewAnswer] = useState('')

  const onSubmit = async () => {
    setError('')
    if (newQuestion.length == 0 || newAnswer.length == 0) {
      setError('Questions and answers cannot be empty')
      return
    }

    try {
      setSubmitting(true)
      await uploadQAPair({
        // QA pairs are encoded to preserve formatting and special characters.
        question: encodeURIComponent(newQuestion),
        answer: encodeURIComponent(newAnswer)
      })
      setNewQuestion('')
      setNewAnswer('')
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message)
      }
    } finally {
      setSubmitting(false)
      queryClient.invalidateQueries({ queryKey: [getQAPairsQueryKey], exact: false })
    }
  }

  return (
    <div className="flex flex-col space-y-8">
      <Typography margin="off">
        Upload a question and answer pair to your organisation’s secure content vault. These pairs will be used by the
        Email Agent.
      </Typography>

      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col space-y-2">
          <Typography margin="off">Question:</Typography>
          <Textarea
            rows={10}
            placeholder="Enter the question that the customer is likely to ask."
            disabled={submitting}
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
          ></Textarea>
        </div>
        <div className="flex flex-col space-y-2">
          <Typography margin="off">Answer:</Typography>
          <Textarea
            rows={10}
            placeholder="Enter the answer to the question."
            disabled={submitting}
            value={newAnswer}
            onChange={(e) => setNewAnswer(e.target.value)}
          ></Textarea>
        </div>
      </div>

      <div className="flex flex-row justify-end space-x-4">
        <Button size="default" align="start" onClick={onSubmit}>
          Upload
        </Button>
        {error && <p className="mt-[0.35rem] text-destructive">{error}</p>}
      </div>
    </div>
  )
}
