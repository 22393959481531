import { Separator } from '@repo/ui/components/separator'
import { useAuth } from '../../../../authentication'
import { UsersForm } from './users-form'

export default function SettingsUsersPage() {
  const auth = useAuth()
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Users</h3>
        {auth.user?.org.admin === true ? (
          <p className="text-sm text-muted-foreground">Manage users, roles and permissions.</p>
        ) : (
          <p className="text-sm text-muted-foreground">Please contact an admin to manage users.</p>
        )}
      </div>
      <Separator />
      <UsersForm />
    </div>
  )
}
