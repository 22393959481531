import { useQuery, useQueryClient } from '@tanstack/react-query'
import { StatusCodes } from 'http-status-codes'
import React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router'
import { z } from 'zod'
import { apiErrorHandler } from './api/errorHandler'
import { parseDataOfType } from './common/parseDataType'

const userProfileSchema = z.object({
  first_name: z.string(),
  surname: z.string(),
  email: z.string().email(),
  sys_admin: z.boolean(),
  org: z.object({
    name: z.string(),
    slug: z.string(),
    admin: z.boolean()
  }),
  enabled_features: z.object({
    search_agent: z.boolean(),
    email_agent: z.boolean(),
    email_agent_dev: z.boolean().optional(),
    script_writer_agent: z.boolean(),
    story_board_agent: z.boolean(),
    search_agent_dev: z.boolean().optional()
  })
})
type UserProfile = z.infer<typeof userProfileSchema> | null

export const UserPwSchema = z
  .string()
  .min(8, { message: 'Password must be at least 8 characters long' })
  .and(z.string().regex(/[A-Z]/, { message: 'Password must contain at least one uppercase letter' }))
  .and(z.string().regex(/[a-z]/, { message: 'Password must contain at least one lowercase letter' }))
  .and(z.string().regex(/[0-9]/, { message: 'Password must contain at least one number' }))
  .and(z.string().regex(/[^A-Za-z0-9]/, { message: 'Password must contain at least one special character' }))

interface AuthContextType {
  user: UserProfile
  login: (email: string, password: string, callback: VoidFunction) => Promise<void>
  logout: (callback: VoidFunction) => Promise<void>
  verifyUser: (email: string, token: string, password1: string, password2: string) => Promise<void>
  passwordReset: (email: string) => Promise<void>
}

const AuthContext = React.createContext<AuthContextType>(null!)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<UserProfile>(null)
  const navigate = useNavigate()
  const pathName = useLocation().pathname
  const queryClient = useQueryClient()

  const { isLoading } = useQuery({
    queryKey: ['userProfile'],
    retry: false,
    // Paths that don't redirect to login if not authenticated
    enabled: pathName !== '/app/login' && pathName !== '/app/user/verify' && pathName !== '/app/user/password-reset',
    // 404 page is a mess, add once fixed.  Until then, redirect to login.
    // pathName !== '/app/404',
    queryFn: async () => {
      const response = await fetch(`${window.location.origin}/api/user/profile`, {
        method: 'GET',
        credentials: 'same-origin'
      })
      if (!response.ok) {
        if (response.status === StatusCodes.UNAUTHORIZED) {
          setUser(null)
          navigate('/app/login')
        }
        throw new Error('Error fetching user profile')
      }

      const userProfile = parseDataOfType(await response.json(), userProfileSchema)
      setUser(userProfile)
      return userProfile
    }
  })

  const login = async (email: string, password: string, callback: VoidFunction) => {
    // Logout before login to clear existing session cookie from browser. Needed to allow login to a different org from the same browser.
    await logout(() => {})
    // Remove all queries when logging in to prevent cross-org data if logging out from one org an into another.
    queryClient.removeQueries()

    type LoginData = {
      email: string
      password: string
    }
    const loginData: LoginData = {
      email,
      password
    }
    const response = await fetch(`${window.location.origin}/api/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify(loginData)
    })
    if (!response.ok) {
      throw new Error('Invalid login details, please try again')
    }

    const userProfile = parseDataOfType(await response.json(), userProfileSchema)
    setUser(userProfile)

    callback()
  }

  const logout = async (callback: VoidFunction) => {
    queryClient.removeQueries()

    const response = await fetch(`${window.location.origin}/api/user/logout`, {
      method: 'POST',
      credentials: 'same-origin'
    })
    // If 401 then already logged out, OK.
    if (!response.ok && response.status !== StatusCodes.UNAUTHORIZED) {
      throw new Error('Error logging out')
    }

    setUser(null)
    callback()
  }

  const verifyUser = async (email: string, token: string, password1: string, password2: string) => {
    type VerifyUserRequest = {
      email: string
      verification_token: string
      password1: string
      password2: string
    }
    const verifyUserRequest: VerifyUserRequest = {
      email,
      verification_token: token,
      password1: password1,
      password2: password2
    }
    const response = await fetch(`${window.location.origin}/api/user/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(verifyUserRequest)
    })
    await apiErrorHandler(response)
  }

  const passwordReset = async (email: string) => {
    type PasswordResetRequest = {
      email: string
    }
    const passwordResetRequest: PasswordResetRequest = {
      email
    }
    const response = await fetch(`${window.location.origin}/api/user/password-reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(passwordResetRequest)
    })
    await apiErrorHandler(response)
  }

  const value = { user, login, logout, verifyUser, passwordReset }

  return <AuthContext.Provider value={value}>{isLoading ? '...' : children}</AuthContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export function useAuth() {
  return React.useContext(AuthContext)
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user) {
    // Redirect them to the /app/login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/app/login" state={{ from: location }} replace />
  }

  return children
}
