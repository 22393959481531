import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@repo/ui/components/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@repo/ui/components/form'
import { Input } from '@repo/ui/components/input'
import { Typography } from '@repo/ui/components/typography'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'
import { z } from 'zod'
import { useAuth } from '../../authentication'

const LoginSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  password: z.string()
})
type LoginType = z.infer<typeof LoginSchema>

export function LoginPage() {
  // hooks
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const from = location.state?.from?.pathname || '/app'
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const form = useForm<LoginType>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  async function onSubmit(data: LoginType) {
    try {
      setLoading(true)

      await auth.login(data.email, data.password, () => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true })
      })

      form.reset()
    } catch (e) {
      console.error(e)
      if (e instanceof Error) {
        setError(e.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex h-[400px] min-h-[760px] w-full bg-[url('/app/images/hero_image.png')] bg-cover bg-center">
      <div className="m-auto flex flex-col items-center justify-center space-y-4">
        <Typography size={'h1'} slot="h1" className="text-5xl">
          Login
        </Typography>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-[30em] max-w-[90vw] animate-appear space-y-6 rounded-sm bg-white p-9 py-9 opacity-0"
          >
            {error && <p className="mt-2 text-destructive">{error}</p>}
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="hidden">Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="hidden">Password</FormLabel>
                  <FormControl>
                    <Input type="password" placeholder="Password" {...field} className="" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="mt-4 w-full" disabled={loading}>
              Login
            </Button>
            {error && (
              <Button
                type="button"
                className="mt-4 w-full"
                variant="link"
                onClick={() => navigate('/app/user/password-reset')}
              >
                Forgot your password?
              </Button>
            )}
          </form>
        </Form>
      </div>
    </div>
  )
}
